import { Box, Button, Paper, Typography } from "@mui/material";
import { GoogleButtonIcon } from "../../components/icons/GoogleButton";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useAuth } from "../../providers/AuthContextProvider";
import { logosvg, buttonstyle, buttonstyle2, buttonstyle3, buttonstyle4, buttonstyle5, buttonstyle6, buttonstyle7 } from "./style"
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";

export const LoginRoute = () => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          maxHeight: "382px",
          padding: "0px !important",
          backgroundColor: "#013B12",
          justifyContent: "center",
          display: "flex",
          borderRadius: "0px !important",
          border: "0px !important"
        }}>
        <Box sx={{ paddingTop: "60px", maxWidth: "1400px" }}>
          <Box>
            <img src={require("../../assets/Logoss.png")}
              alt={require("../../assets/Logoss.png")} width={"auto"} />
          </Box>
          <Box sx={{ paddingTop: "45px !important", paddingBottom: "25px !important" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={"h4"} sx={{ color: "#02BD36", fontWeight: "900 !important", fontSize: "36px", marginTop: "9px", fontFamily: "Barlow Condensed, sans-serif !important" }}>LESS TIME ON ADMIN, MORE TIME WITH YOUR CLIENTS</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box sx={{ float: "right" }}>
                  <Button
                    disabled={loading}
                    sx={buttonstyle}
                  >Pricing
                  </Button>
                  <Button
                    disabled={loading}
                    sx={buttonstyle2}
                  >Contact
                  </Button>
                  <Button
                    disabled={loading}
                    sx={buttonstyle7}
                    onClick={() => { window.location.href = "https://coachplus.club/blog" }}
                  >Blog
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          maxHeight: "800px",
          padding: "0px !important",
          justifyContent: "center",
          display: "flex",
          borderRadius: "0px !important",
          border: "0px !important"
        }}>
        <Box sx={{
          paddingTop: "40px", minWidth: "1400px",
        }}>
          <Grid container spacing={2} sx={{ justifyContent: "space-between", maxWidth: "1400px", paddingLeft: "33px", paddingRight: "0px" }} >
            <Grid item xs={4} sx={{ minWidth: "660px !important", paddingLeft: "0px !important", marginLeft: "0px !important" }}>
              <Box sx={{ borderRadius: "24px", backgroundColor: "#E3E2E0", height: "600px" }}>
                <Box sx={{ textAlign: "center", marginBottom: "8%", paddingTop: "10%" }}>
                  <Typography variant={"h3"} sx={{ color: "#525A65", fontWeight: "900 !important", fontFamily: "Barlow Condensed, sans-serif !important", fontSize: "64px" }}>LET COACHPLUS BUILD PLANS AND HANDLE SCHEDULING FOR YOU</Typography>
                </Box>
                <Box sx={{ textAlign: "center", margin: "0px 15%" }}>
                  <Typography sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "20px" }}>Try CoachPlus with no upfront costs. CoachPlus is free up until 3 client.</Typography>
                </Box>
                <Box sx={{ textAlign: "center", marginTop: "8%" }}>
                  <Button onClick={signIn} sx={buttonstyle3}>
                    <div style={{ paddingRight: "9px", paddingTop: "6px" }}>
                      <GoogleButtonIcon />
                    </div>
                    Continue with Google
                  </Button>
                </Box>
                <Box sx={{ textAlign: "center", margin: "4% 33%" }}>
                  <Typography variant="body1" sx={{ color: "#525A65", fontWeight: "400 !important", fontSize: "12px" }}>
                    By clicking continue, you agree to our <a href="https://coachplus.club/terms-and-conditions" style={{ color: "#525A65" }}>User Agreement</a> and <a href="https://coachplus.club/privacy-policy" style={{ color: "#525A65" }}>Privacy Policy.</a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ minWidth: "660px !important", paddingLeft: "0px !important", marginLeft: "0px !important" }}>
              <Box sx={{ borderRadius: "24px" }}>
                <img src={require("../../assets/loginpagephoto.png")}
                  alt={require("../../assets/loginpagephoto.png")} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          maxHeight: "800px",
          padding: "0px !important",
          justifyContent: "center",
          display: "flex",
          borderRadius: "0px !important",
          border: "0px !important"
        }}>
        <Box sx={{
          paddingTop: "60px", minWidth: "1400px",
        }}>

          <Box sx={{ textAlign: "center" }}>
            <Typography variant={"h3"} sx={{ color: "#A080E8", fontWeight: "900 !important", fontFamily: "Barlow Condensed, sans-serif !important", fontSize: "64px" }}>
              A PRODUCTIVITY ASSISTANT FOR
              <br />
              COACHES AND TRAINERS
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", margin: "1% 15%" }}>
            <Typography sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "24px" }}>Simplify your workflow, enhance client engagement, and focus on growth.</Typography>
          </Box>
          <Box sx={{ textAlign: "center", margin: "0% 21%" }}>
            <Typography variant="body1" sx={{ color: "#525A65", fontWeight: "300 !important", fontSize: "24px" }}>
              CoachPlus integrates with the tools you already use, like WhatsApp, email and calendars—no extra downloads needed for you or your clients.
            </Typography>
          </Box>
          <br />
          <Box sx={{ textAlign: "center", margin: "0% 17%" }}>
            <Typography variant="body1" sx={{ color: "#525A65", fontWeight: "300 !important", fontSize: "24px" }}>
              It’s a simple platform that brings scheduling, feedback, training plans, progress tracking, accountability, and communication into one place. By keeping everything organised, Coach+ helps you save time and manage your schedule more effectively.
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", margin: "1% 0%" }}>
            <Button
              disabled={loading}
              sx={buttonstyle4}
            >Try for free</Button>
          </Box>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          maxHeight: "800px",
          padding: "0px !important",
          justifyContent: "center",
          display: "flex",
          borderRadius: "0px !important",
          border: "0px !important"
        }}>
        <Box sx={{
          paddingTop: "60px", minWidth: "1400px",
        }}>
          <Grid container spacing={2} sx={{ justifyContent: "space-between", maxWidth: "1400px", paddingLeft: "33px", paddingRight: "0px" }} >
            <Grid item xs={4} sx={{ minWidth: "660px !important", paddingLeft: "0px !important", marginLeft: "0px !important" }}>
              <Box sx={{ borderRadius: "24px" }}>
                <img src={require("../../assets/Rectangle 114.png")}
                  alt={require("../../assets/Rectangle 114.png")} />
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ minWidth: "660px !important", paddingLeft: "0px !important", marginLeft: "0px !important" }}>
              <Box sx={{ borderRadius: "24px", backgroundColor: "#351E06", height: "600px" }}>
                <Box sx={{ textAlign: "center", paddingTop: "21px" }}>
                  <img src={require("../../assets/logohome.png")}
                    alt={require("../../assets/logohome.png")} />
                </Box>
                <Box sx={{ textAlign: "center", margin: "27% 0%" }}>
                  <Button
                    disabled={loading}
                    sx={buttonstyle5}
                  >Rediscover your why</Button>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant={"h3"} sx={{ color: "#E67B02", fontWeight: "900 !important", fontFamily: "Barlow Condensed, sans-serif !important", fontSize: "60px" }}>
                    COACH MORE,MANAGE LESS
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          maxHeight: "800px",
          padding: "0px !important",
          justifyContent: "center",
          display: "flex",
          borderRadius: "0px !important",
          border: "0px !important"
        }}>
        <Box sx={{
          paddingTop: "60px", minWidth: "1400px",
        }}>
          <Box sx={{ textAlign: "center", margin: "0% 24%" }}>
            <Typography variant={"h3"} sx={{ color: "#2998E9", fontWeight: "900 !important", fontFamily: "Barlow Condensed, sans-serif !important", fontSize: "64px" }}>
              EFFORTLESS COACHING,
              <br />
              NO APP REQUIRED</Typography>
          </Box>
          <Box sx={{ textAlign: "center", margin: "1% 23%" }}>
            <Typography sx={{ color: "#525A65", fontWeight: "300 !important", fontSize: "24px" }}>CoachPlus makes it easy for coaches and athletes to connect. No downloads or complicated steps—just a seamless, hassle-free experience designed for your success.</Typography>
          </Box>
          <br />
          <Box sx={{ textAlign: "center", margin: "0% 38%" }}>
            <Typography variant="body1" sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "20px" }}>
              Try CoachPlus with no upfront costs. CoachPlus is <span style={{ color: "#2998E9" }}>free</span> up until <span style={{ color: "#2998E9" }}>3 client</span>.
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", margin: "4% 0%" }}>
            <Button
              disabled={loading}
              sx={buttonstyle6}
            >Pricing</Button>
          </Box>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          minHeight: "200px",
          padding: "0px !important",
          justifyContent: "center",
          display: "flex",
          borderRadius: "0px !important",
          backgroundColor: "#E3E2E0",
          borderTop: "3px solid transparent",
          borderWidth: "2px",
          borderImage: "linear-gradient(to right, #02BD36 0%, #A080E8 35%, #E67B02 65%, #2998E9 100%) 1 0 0 0",
          backgroundOrigin: "padding - box, border - box",
        }}>
        <Grid container spacing={3} sx={{ paddingTop: "4%" }}>
          <Grid item xs>
            <Box sx={{ paddingLeft: "20px" }}>
              <a href="https://coachplus.club/terms-and-conditions" style={{ color: "#525A65", fontSize: "14px" }}>User Agreement</a>
              <br />
              <a href="https://coachplus.club/privacy-policy" style={{ color: "#525A65", fontSize: "14px" }}>Privacy Policy</a>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center" }}>
            <img src={require("../../assets/Logofoteer.png")}
              alt={require("../../assets/Logofoteer.png")} />
            <Typography variant="body1" sx={{ color: "#000000", fontSize: "12px", marginTop: "10px !important" }}>
              © 2024 CoachPlus ltd. <a href="#" style={{ color: "#525A65" }}>contact@coachplus.club</a>
            </Typography>
          </Grid>
          <Grid item xs>
            <Box sx={{ float: "right", paddingRight: "20px" }}>
              <a href="https://www.linkedin.com/company/coachplusclub" style={{ color: "#525A65", fontSize: "14px" }}>LinkedIn</a>
              <br />
              <a href="https://www.instagram.com/coachplusclub/" style={{ color: "#525A65", fontSize: "14px" }}>Instagram</a>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {/* <Box px={2} height="100%">
          <Paper
            variant="outlined"
            sx={{
              py: 8,
              px: 4,
              mb: 2,
              maxWidth: 400,
              mx: "auto",
              mt: 6,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src="/logo-black.png" alt="Coach+" style={{ width: 64 }} />
              <Typography variant="h4" sx={{ mb: 4 }}>
                Coach+
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, textAlign: "center" }}>
                Sign in with Google and continue to your dashboard.
              </Typography>
              <Button onClick={signIn}>
                <GoogleButtonIcon />
              </Button>
            </Box>
          </Paper>
        </Box> */}
    </>
  );
};
