export const Typography1style = {
    fontSize: "24px", fontWeight: "600 !important", color: "#525A65"
}

export const Typography2style = {
    fontSize: "16px", color: "#525A65"
}

export const Typography3style = {
    fontSize: "16px", color: "#525A65", fontWeight: "600 !important"
}

export const subsStyle = {
    marginLeft: "auto",
    color: "#02BD36",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 8px !important",
    width: "103px !important",
    "&:hover": {
        color: "#02BD36",
        backgroundColor: "unset"
    },
}


export const signoutStyle = {
    marginLeft: "auto",
    width: "100% !important",
    color: "#FFFFFF",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 8px !important",
    minWidth: "96px !important",
    backgroundColor: "#525A65",
    "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#525A65"
    },
}



export const formtextfieldStyle = {
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "16px  24px",
        color: "#525A65",
        fontWeight: "400 !important"
    }
}


export const formautodStyle1 = {
    width: "100%",
    ".css-feqhe6": {
        paddingRight: "8px !important"
    },
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "7.5px 5px 7.5px 18px !important",
        color: "#525A65",
        fontWeight: "400 !important"
    }
}

export const formautodStyle2 = {
    mr: 1,
    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "14 24px",
        color: "#525A65",
        fontWeight: "400 !important"
    }
}

export const submitStyle = {
    marginLeft: "auto",
    width: "100% !important",
    color: "#FFFFFF",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 8px !important",
    minWidth: "96px !important",
    backgroundColor: "#525A65",
    "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#525A65"
    },
}

export const formDateFieldStyle = {
    ".css-10o2lyd-MuiStack-root": {
        paddingTop: "0px !important",
    },
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "16px  24px",
        color: "#525A65",
        fontWeight: "400 !important"
    }
}