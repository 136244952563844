import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import ResponsesModal from "../../components/ResponsesModal/ResponsesModal";
import { useEffect, useState } from "react";
import OnboardingFormModal from "../../components/OnboardingFormModal/OnboardingFormModal";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import {
  deleteOnboardingForm,
  getOnboardingFormsByUserId,
} from "../../services/query";
import { searchstyle, addformstyle, Svgedit, paper, Typography1, table, Svgdelete } from "./style"
import Divider from "@mui/material/Divider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { CoachProfileCard } from "../../components/CoachProfileCard/CoachProfileCard"
import { getAthletesByCoachId } from "../../services/query";


export const OnboardingFormsRoute = () => {
  const [forms, setForms] = useState([]);
  const [responsesOpen, setResponseOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [athletes, setAthletes] = useState([]);
  const supabase = useSupabase();
  const { user } = useAuth();


  const getForms = async () => {
    const { data: _forms } = await getOnboardingFormsByUserId(
      supabase,
      user.id
    );
    console.log(_forms);
    setForms(_forms || []);
  };
  const getAthletes = () => {
    getAthletesByCoachId(supabase, user.id).then(({ data }) => {
      setAthletes(data || []);
    });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getForms();
    getAthletes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <PageContainer>
        <CoachProfileCard user={user} athletes={athletes} />
        <Paper variant="outlined" mb={3} sx={paper}>
          <Box sx={{ display: "flex", alignItems: "center", padding: "29px 23px 16px 23px" }}>
            <Typography variant="subtitle" sx={Typography1}>Forms</Typography>
            <Button
              startIcon={<Add />}
              size="small"
              sx={addformstyle}
              onClick={() => setFormOpen(true)}
            >
              Add a new form
            </Button>
          </Box>
          <Box px={2} pb={2} sx={{ padding: "13px 23px 31px 23px" }}>
            <TextField
              id="outlined-search"
              label="Search forms by name..."
              type="search"
              fullWidth
              value={search}
              sx={searchstyle}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Divider sx={{ padding: "0px" }} />
          <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table" sx={table}>
              <TableBody>
                {forms
                  .filter((form) =>
                    form.title.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((form) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={form.id}
                    >
                      <TableCell
                        key={form.id}
                        sx={{
                          p: {
                            xs: 1,
                            sm: 2,
                          },
                          backgroundColor: "#F1F3F4",
                          padding: "15px",
                          borderBottom: "0px",
                        }}
                        id={form.id}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: "19px", marginTop: "7px", marginLeft: "8px" }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5ZM5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44771 7 5 7H15C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5H5ZM5 9C4.44772 9 4 9.44772 4 10C4 10.5523 4.44771 11 5 11H15C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9H5ZM4 14C4 13.4477 4.44772 13 5 13H9C9.55228 13 10 13.4477 10 14C10 14.5523 9.55228 15 9 15H5C4.44772 15 4 14.5523 4 14Z" fill="#DAD9D8" />
                            </svg>
                          </span>
                          <Typography variant="subtitle2" sx={{ color: "#525A65", fontSize: "12px" }}>{form.title}</Typography>
                          <Button
                            startIcon={<Svgedit />}
                            size="small"
                            onClick={() => setFormOpen(form)}
                            sx={{
                              minWidth: "70px",
                              ".css-8je8zh-MuiTouchRipple-root": {
                                display: "none"
                              },
                              ".MuiButton-icon": {
                                marginLeft: "29px !important"
                              },
                              marginLeft: "auto",
                              "&:hover": {
                                backgroundColor: "unset !important"
                              },

                            }}></Button>

                          <Button
                            startIcon={<Svgdelete />}
                            size="small"
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Deleting onboarding form "${form.title}". Please confirm.`
                                )
                              ) {
                                deleteOnboardingForm(supabase, form.id)
                                  .then(() => {
                                    getForms();
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              }
                            }}
                            sx={{
                              display:"contents",
                              minWidth: "70px",
                              ".css-8je8zh-MuiTouchRipple-root": {
                                display: "none"
                              },
                              ".MuiButton-icon": {
                                marginLeft: "29px !important"
                              },
                              "&:hover": {
                                backgroundColor: "unset !important"
                              },

                            }}></Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Grid2 container spacing={2} sx={{ pb: 10 }}>
            {forms
              .filter((form) =>
                form.title.toLowerCase().includes(search.toLowerCase())
              )
              .map((form) => (
                <Grid2 xs={12} sm={4} key={form.id}>
                  <Paper variant="outlined">
                    <Box p={2}>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Avatar
                          sx={{
                            backgroundColor: "success.light",
                            fontSize: 24,
                            mr: 2,
                          }}
                        >
                          {form.icon}
                        </Avatar>
                        <Typography variant="subtitle2">{form.title}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={2}>
                        <span>
                          <strong>Sent to:</strong>{" "}
                          {form.onboarding_form_response.length}
                        </span>
                        <span style={{ marginLeft: 12 }}>
                          <strong>Completed:</strong>{" "}
                          {
                            form.onboarding_form_response.filter(
                              (r) => r.status === "completed"
                            ).length
                          }
                        </span>
                      </Box>
                      <Box display="flex">
                        <Button
                          color="primary"
                          size="small"
                          startIcon={<Edit />}
                          onClick={() => setFormOpen(form)}
                        >
                          Edit
                        </Button>
                        <IconButton
                          sx={{ ml: "auto" }}
                          color="pink"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Deleting onboarding form "${form.title}". Please confirm.`
                              )
                            ) {
                              deleteOnboardingForm(supabase, form.id)
                                .then(() => {
                                  getForms();
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                            }
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                </Grid2>
              ))}
          </Grid2> */}
        </Paper>
      </PageContainer>
      <ResponsesModal
        open={!!responsesOpen}
        formData={responsesOpen}
        handleClose={() => setResponseOpen(false)}
      />
      <OnboardingFormModal
        open={!!formOpen}
        handleClose={() => setFormOpen(false)}
        formData={formOpen === true ? {} : formOpen}
        onSuccess={(form) => {
          setFormOpen(false);
          getForms();
        }}
      />
    </>
  );
};


