import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useEffect, useState } from "react";
import {
  getAthleteByEmail,
  getCoachAthlete,
  getOnboardingFormsByUserId,
  insertAthlete,
  insertCoachAthlete,
  insertOnboardingFormResponse,
  upsertOnboardingForm,
} from "../../services/query";
import { trimAndValidatePhone } from "../../utils/validations";
import InputLabel from '@mui/material/InputLabel';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { countries } from "../countrycode/countrycode";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputAdornment from "@mui/material/InputAdornment";



export default function AthleteInviteModal({ open, handleClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const [email, setEmail] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedForm, setSelectedForm] = useState(null);
  const [default_forms, setDefaultfromst] = useState(false);

  const supabase = useSupabase();
  const { user } = useAuth();

  const handleInvite = async () => {
    setLoading(true);
    if (!email || !email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      setLoading(false);
      return alert("Please enter a valid Gmail address");
    }
    if (!selectedForm) {
      setLoading(false);
      return alert("Please select an onboarding form");
    }

    const phone_number = `${areaCode}${phone}`;
    console.log(phone_number);
    if (trimAndValidatePhone(phone_number) === false) {
      setLoading(false);
      return alert(
        "Please enter a valid phone number consisting of digits only"
      );
    }

    let athlete = {
      status: "PENDING",
      email,
      phone_number,
    };

    const { data: existingAthlete, error: _athleteError } =
      await getAthleteByEmail(supabase, email);

    if (existingAthlete.length > 0) {
      setLoading(false);
      return alert(
        "An athlete with this email already exists in the system for you or another coach."
      );
    } else {
      const { data: newAthlete, error: nae } = await insertAthlete(
        supabase,
        athlete
      );
      if (nae) {
        setLoading(false);
        return alert(
          "An athlete with this phone number already exists in the system for you or another coach."
        );
      }
      console.log({ newAthlete });
      if (newAthlete) {
        athlete = newAthlete[0];
      }
    }

    console.log({ athlete, _athleteError });

    // create "onboarding_form_response"
    console.log({
      athlete_id: athlete.id,
      coach_id: user.id,
      form_id: selectedForm.id,
      status: "sent",
    });

    const { data: newResponse, error: newResponseError } =
      await insertOnboardingFormResponse(supabase, {
        athlete_id: athlete.id,
        coach_id: user.id,
        form_id: selectedForm.id,
        status: "sent",
      });

    console.log({ newResponse, newResponseError });
    if (newResponseError) {
      const { data: default_forms } = await supabase
        .from("default_forms")
        .select("*")
        .eq("id", selectedForm.id);
      console.log(default_forms[0].title);
      for (let i of default_forms) {
        const { data: insert_default_forms, error } =
          await upsertOnboardingForm(supabase, {
            user_id: user.id,
            custom_questions: i.custom_questions,
            title: i.title,
            welcome_message: i.welcome_message,
            icon: i.icon,
          });
        console.log(insert_default_forms, error);
        for (let x of insert_default_forms) {
          const { data: newResponse, error: newResponseError } =
            await insertOnboardingFormResponse(supabase, {
              athlete_id: athlete.id,
              coach_id: user.id,
              form_id: x.id,
              status: "sent",
            });

          console.log({ newResponse, newResponseError });
          const { data: coachAthlete, error: coachAthleteError } =
            await getCoachAthlete(supabase, user.id, athlete.id);

          console.log({ coachAthlete, coachAthleteError });

          if (coachAthlete.length === 0) {
            const { data: newCoachAthlete, error: newCoachAthleteError } =
              await insertCoachAthlete(supabase, [
                {
                  coach_id: user.id,
                  athlete_id: athlete.id,
                },
              ]);
            console.log({ newCoachAthlete, newCoachAthleteError });
          }
          // send welcome message
          console.log({
            phone: phone_number,
            onboarding_url: `https://app.coachplus.club/onboarding-form/${newResponse[0].id}`,
            coach_name: user.full_name,
          });
          await supabase.functions.invoke("welcome-wp", {
            body: {
              phone: phone_number,
              onboarding_url: `https://app.coachplus.club/onboarding-form/${newResponse[0].id}`,
              coach_name: user.full_name,
            },
          });
        }
      }
    } else {
      // find coach_athlete
      const { data: coachAthlete, error: coachAthleteError } =
        await getCoachAthlete(supabase, user.id, athlete.id);

      console.log({ coachAthlete, coachAthleteError });

      if (coachAthlete.length === 0) {
        const { data: newCoachAthlete, error: newCoachAthleteError } =
          await insertCoachAthlete(supabase, [
            {
              coach_id: user.id,
              athlete_id: athlete.id,
            },
          ]);
        console.log({ newCoachAthlete, newCoachAthleteError });
      }

      // send welcome message
      console.log({
        phone: phone_number,
        onboarding_url: `https://app.coachplus.club/onboarding-form/${newResponse[0].id}`,
        coach_name: user.full_name,
      });
      await supabase.functions.invoke("welcome-wp", {
        body: {
          phone: phone_number,
          onboarding_url: `https://app.coachplus.club/onboarding-form/${newResponse[0].id}`,
          coach_name: user.full_name,
        },
      });
    }
    setLoading(false);
    onSuccess();
    handleClose();
    // create coach_athletes
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const getForms = async () => {
      const { data: _forms } = await getOnboardingFormsByUserId(
        supabase,
        user.id
      );
      if (_forms.length === 0) {
        const { data: _forms } = await supabase
          .from("default_forms")
          .select("*");
        setDefaultfromst(true);
        setSelectedForm(_forms[0]);
        setForms(_forms || []);
      } else {
        console.log(false);
        setDefaultfromst(false);
        setSelectedForm(null);
        setForms(_forms || []);
      }
    };

    getForms();
  }, [user, open, supabase]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth sx={{

      ".MuiPaper-root": {
        borderRadius: "24px",
        width: "529px",
        minHeight: "525px",
        backgroundColor: "#FBFBFB",
      },
      ".css-13680nc .MuiOutlinedInput-root": {
        padding: "7px 18px !important"
      }
    }}>
      <Box sx={{ padding: "40px 23px 16px 23px", minHeight: "525px", maxHeight: "670px" }}>
        <DialogTitle sx={{ color: "#000000", fontWeight: "600 !important", fontSize: "16px", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              Invite your client
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
              <Button onClick={handleClose} startIcon={<CloseIcon />} size="small" sx={close}></Button>
            </Grid>
          </Grid>
        </DialogTitle>
        {/* <Box display="flex" alignItems="center" mb={0}>
            <Typography variant="subtitle">Invite your client</Typography>
          </Box> */}
        <DialogContent sx={{ paddingBottom: "5px !important" }}>
          <DialogContentText sx={{ mb: 2, fontSize: "14px", color: "#000000", fontWeight: "400 !important", paddingTop: "10px", paddingBottom: "10px" }}>
            Select an onboarding form, enter your clients Gmail address and invite them to join you in Coach Plus.
          </DialogContentText>
          <Box sx={{ mb: 3 }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Gmail address
            </InputLabel>
            <TextField
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "0px solid #DAD9D8"
                },
                ".MuiInputBase-input": {
                  fontSize: "12px",
                  padding: "14px 24px",
                  color: "#525A65",
                  fontWeight: "400 !important"
                }
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  Country code
                </InputLabel>
                <Autocomplete
                  options={countries}
                  onChange={(e, v) => setAreaCode(v?.phone)}
                  sx={{
                    width: "100%",
                    ".css-feqhe6": {
                      paddingRight: "8px !important"
                    },
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      border: "0px solid #DAD9D8"
                    },
                    ".MuiInputBase-input": {
                      fontSize: "12px",
                      padding: "7.5px 5px 7.5px 18px !important",
                      color: "#525A65",
                      fontWeight: "400 !important"
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="+00"
                    // sx={{
                    //   ".css-vubbuv": {
                    //     display: "none"
                    //   },
                    //   "&:hover": {
                    //     ".MuiInputAdornment-root": {
                    //       display: "none"
                    //     }
                    //   },
                    //   "&:focus": {
                    //     ".MuiInputAdornment-root": {
                    //       display: "none"
                    //     }
                    //   },
                    //   "&:active": {
                    //     ".MuiInputAdornment-root": {
                    //       display: "none"
                    //     }
                    //   }
                    // }} InputProps={{
                    //   ...params.InputProps,
                    //   startAdornment: (
                    //     <InputAdornment position="end">
                    //       <KeyboardArrowDownIcon sx={{
                    //         display: "inline-block", position: "absolute", marginLeft: "95px", ":hover": {
                    //           display: "none"
                    //         }
                    //       }} />
                    //     </InputAdornment>
                    //   )
                    // }} 
                    />
                  )}
                  getOptionLabel={(option) => `+(${option.phone})`}
                />
              </Grid>
              <Grid item xs={8} sx={{
                paddingLeft: "0px !important",
                ".css-cmlpbm": {
                  widht: "100% !important"
                },
                ".MuiFormControl-root": {
                  widht: "100% !important"
                }

              }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  Telephone number
                </InputLabel>
                <TextField
                  name="phone"
                  type="number"
                  placeholder="Telephone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{
                    mr: 1,
                    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      border: "0px solid #DAD9D8"
                    },
                    ".MuiInputBase-input": {
                      fontSize: "12px",
                      padding: "14 24px",
                      color: "#525A65",
                      fontWeight: "400 !important"
                    }
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>

          {/* <Box sx={{ mb: 3 }} display="flex" alignItems="center">
             <Typography sx={{ mr: 1 }}>+</Typography> 
            <TextField
              value={areaCode}
              onChange={(e) => setAreaCode(e.target.value)}
              label="Country Code"
              name="country_code"
              type="number"
              sx={{
                mr: 1, width: 120
              }}
              fullWidth
            />
            <TextField
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              label="Phone Number"
              name="phone"
              type="number"

              fullWidth
            />
          </Box> */}
          {default_forms === true ? (
            <></>
          ) : (
            <>
              <Box mb={3}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  Form
                </InputLabel>
                <Autocomplete
                  options={forms}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      border: "0px solid #DAD9D8"
                    },
                    ".MuiInputBase-input": {
                      fontSize: "12px",
                      padding: "14px 24px",
                      color: "#525A65",
                      fontWeight: "400 !important"
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select a Form"
                    //  sx={{
                    //   ".css-vubbuv": {
                    //     display: "none"
                    //   },
                    //   "&:hover": {
                    //     ".MuiInputAdornment-root": {
                    //       display: "none"
                    //     }
                    //   },
                    //   "&:focus": {
                    //     ".MuiInputAdornment-root": {
                    //       display: "none"
                    //     }
                    //   },
                    //   "&:active": {
                    //     ".MuiInputAdornment-root": {
                    //       display: "none"
                    //     }
                    //   }
                    // }} InputProps={{
                    //   ...params.InputProps,
                    //   startAdornment: (
                    //     <InputAdornment position="end">
                    //       <KeyboardArrowDownIcon sx={{
                    //         display: "inline-block", position: "absolute", marginLeft: "95px", ":hover": {
                    //           display: "none"
                    //         }
                    //       }} />
                    //     </InputAdornment>
                    //   )
                    // }} 
                    />
                  )}
                  onChange={(e, v) => setSelectedForm(v)}
                  getOptionLabel={(option) => ` ${option.title}`}
                  getOptionKey={(option) => option.id}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleInvite}
            disabled={loading}
            sx={invitestyle}
          >
            Invite
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}






const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F3F6F9',
    border: '1px solid',
    borderColor: '#E0E3E7',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
  },
}));

export const addnewquestionstyle = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "30px",
  fontSize: "12px",
  ".MuiSvgIcon-root": {
    fontSize: "12px !important",
    paddingLeft: "2px",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}

export const invitestyle = {
  marginLeft: "auto",
  color: "#A080E8",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "30px",
  marginLeft: "auto",
  fontSize: "13px",
  padding: "11px 8px !important",
  minWidth: "96px !important",
  "&:hover": {
    color: "#A080E8",
    backgroundColor: "unset"
  },
}

export const close = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  float: "right",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  backgroundColor: "#EEEEEE",
  ".MuiSvgIcon-root": {
    fontSize: "18px !important",
    paddingLeft: "0px",
    color: "#525A65"
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}