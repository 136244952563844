export const logosvg = () => {
    return (
        <svg width="1360" height="188" viewBox="0 0 1360 188" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M183.969 21.8414H170.756C169.799 21.8414 169.016 21.0585 169.016 20.1017V5.17547C169.016 4.14038 168.173 3.29665 167.137 3.29665H139.129C138.311 3.29665 137.589 3.82724 137.337 4.60139L135.484 19.7016C135.337 20.9194 134.006 21.6152 132.936 21.0237C131.735 20.3627 130.492 19.4146 129.804 18.7883C127.908 17.0747 118.523 8.90704 111.164 5.46252C102.866 1.57439 92.7932 0 81.268 0C69.7428 0 59.8963 1.94841 50.1195 5.85394C40.3426 9.75947 31.6878 15.5351 24.1464 23.1896C16.605 30.8441 10.6989 40.2382 6.41063 51.3807C2.13978 62.5145 0 75.3271 0 89.801C0 109.955 4.08819 127.351 12.2733 141.973C20.4583 156.595 32.0705 167.851 47.1099 175.731C62.1492 183.612 79.9372 187.552 100.474 187.552C111.686 187.552 123.263 186.056 135.197 183.064C146.783 180.159 156.882 175.636 165.502 169.477C165.998 169.121 166.285 168.547 166.285 167.946V122.167C166.285 121.428 165.85 120.749 165.172 120.454L164.276 120.054C163.623 119.767 162.867 119.871 162.319 120.332C155.142 126.247 147.41 130.657 139.112 133.562C130.474 136.598 121.645 138.111 112.625 138.111C97.3163 138.111 85.4432 133.814 76.9885 125.212C68.5424 116.609 64.3151 104.205 64.3151 87.9831C64.3151 76.9971 65.9242 67.977 69.1426 60.9314C72.361 53.8858 76.6057 48.6582 81.8769 45.2484C87.1394 41.8387 92.8106 40.1339 98.8733 40.1339C103.214 40.1339 107.111 40.908 110.581 42.4302V61.1837C110.581 62.3232 111.582 63.2017 112.712 63.0451L127.673 61.0097C128.813 60.8531 129.787 61.8274 129.63 62.9668L127.786 76.5622C127.743 77.867 128.717 78.5628 129.674 78.5628H167.129C168.164 78.5541 169.008 77.7104 169.008 76.6753V56.9128C169.008 56.043 169.651 55.3036 170.512 55.1906L184.212 53.3291C185.143 53.1987 185.839 52.4071 185.839 51.4677V23.7289C185.839 22.6938 184.995 21.8501 183.96 21.8501L183.969 21.8414Z" fill="#E3E2E0" />
            <path d="M245.889 186.297C231.641 186.297 219.351 183.757 209 178.677C198.657 173.597 190.698 166.36 185.132 156.966C179.565 147.572 176.781 136.395 176.781 123.434C176.781 110.474 179.565 99.1835 185.132 89.7894C190.698 80.3952 198.657 73.1409 209 68.0176C219.342 62.903 231.641 60.3457 245.889 60.3457C260.137 60.3457 272.427 62.903 282.77 68.0176C293.112 73.1322 301.071 80.3865 306.638 89.7894C312.205 99.1835 314.997 110.404 314.997 123.434C314.997 136.464 312.213 147.572 306.638 156.966C301.071 166.36 293.112 173.597 282.77 178.677C272.427 183.757 260.128 186.297 245.889 186.297ZM245.889 153.217C256.118 153.217 261.233 143.249 261.233 123.321C261.233 103.393 256.118 93.4253 245.889 93.4253C235.66 93.4253 230.545 103.393 230.545 123.321C230.545 143.249 235.66 153.217 245.889 153.217Z" fill="#E3E2E0" />
            <path d="M361.891 186.301C354.089 186.301 347.13 184.727 341.033 181.587C334.935 178.446 330.16 174.106 326.707 168.574C323.262 163.042 321.531 156.675 321.531 149.481C321.531 138.269 325.854 129.327 334.492 122.656C343.129 115.984 354.646 112.653 369.05 112.653C374.051 112.653 378.827 112.957 383.376 113.566C387.16 114.071 390.987 114.862 394.875 115.941C396.067 116.271 397.241 115.367 397.241 114.131C397.241 107.234 395.136 102.232 390.935 99.1269C386.734 96.0216 380.001 94.4646 370.755 94.4646C364.083 94.4646 357.342 95.3344 350.523 97.0828C344.025 98.7442 338.084 101.023 332.682 103.92C332.169 104.189 331.569 104.224 331.038 103.989L329.925 103.493C329.247 103.189 328.812 102.519 328.812 101.78V71.1097C328.812 70.3269 329.29 69.6223 330.021 69.3527C337.684 66.4736 346.313 64.2816 355.924 62.7681C366.04 61.1763 376.217 60.3848 386.446 60.3848C409.106 60.3848 425.658 64.1772 436.122 71.7534C446.578 79.3296 451.805 91.4985 451.805 108.243V148.307C451.805 149.342 452.649 150.186 453.684 150.186H464.705C465.74 150.186 466.584 151.03 466.584 152.065V182.413C466.584 183.448 465.74 184.292 464.705 184.292H405.905C405.087 184.292 404.356 183.761 404.113 182.97L400.146 170.27C399.685 168.791 397.737 168.487 396.832 169.739C393.475 174.402 389.126 178.177 383.776 181.047C377.218 184.57 369.928 186.336 361.891 186.336V186.301ZM385.646 157.205C387.69 157.205 389.83 156.849 392.066 156.127C393.892 155.544 395.371 154.787 396.519 153.874C396.971 153.509 397.241 152.969 397.241 152.386V135.512C397.241 134.72 396.737 134.016 395.989 133.737C394.501 133.181 393.005 132.781 391.5 132.546C389.569 132.241 387.769 132.093 386.099 132.093C382.237 132.093 378.992 133.233 376.383 135.503C373.773 137.773 372.46 140.809 372.46 144.593C372.46 148.377 373.669 151.525 376.096 153.796C378.522 156.066 381.706 157.205 385.646 157.205Z" fill="#E3E2E0" />
            <path d="M541.558 186.297C520.039 186.297 503.086 180.634 490.691 169.3C478.304 157.975 472.103 142.266 472.103 122.182C472.103 109.604 474.738 98.6877 480.001 89.4414C485.263 80.1952 492.317 73.0365 501.146 67.9567C509.975 62.8856 519.691 60.3457 530.303 60.3457C538.035 60.3457 544.811 61.6939 550.648 64.3817C555.519 66.6259 559.781 69.5746 563.434 73.2192C564.426 74.2021 566.096 73.8541 566.557 72.532L569.645 63.6598C569.906 62.903 570.619 62.3985 571.419 62.3985H593.33C594.366 62.3985 595.209 63.2422 595.209 64.2773V110.987C595.209 112.022 594.366 112.866 593.33 112.866H564.148C563.208 112.866 562.408 112.17 562.295 111.239C561.556 105.533 559.833 101.202 557.137 98.2528C554.179 95.0344 550.465 93.4253 545.994 93.4253C540.915 93.4253 536.392 95.5998 532.408 99.9577C528.433 104.315 526.441 111.57 526.441 121.721C526.441 131.872 529.207 139.778 534.739 144.284C540.271 148.79 547.282 151.051 555.763 151.051C562.13 151.051 568.288 149.799 574.238 147.302C579.779 144.971 584.623 142.144 588.755 138.83C589.303 138.387 590.051 138.282 590.695 138.569L591.599 138.969C592.278 139.274 592.713 139.944 592.713 140.683V172.223C592.713 172.849 592.408 173.432 591.895 173.78C588.459 176.059 584.145 178.103 578.961 179.93C573.35 181.904 567.288 183.452 560.773 184.592C554.258 185.731 547.856 186.297 541.567 186.297H541.558Z" fill="#E3E2E0" />
            <path d="M618.283 184.274C617.248 184.274 616.404 183.43 616.404 182.395V150.159V46.5541C616.404 45.519 615.56 44.6753 614.525 44.6753H601.8C600.765 44.6753 599.921 43.8315 599.921 42.7964V15.3534C599.921 14.3879 600.66 13.5789 601.626 13.4832L668.916 7.1335C670.02 7.02913 670.968 7.89895 670.968 9.00364V70.8919C670.968 72.4837 672.821 73.3535 674.048 72.3271C683.546 64.3508 695.593 60.3583 710.189 60.3583C723.906 60.3583 733.961 64.2464 740.372 72.0052C746.774 79.7728 749.975 91.4633 749.975 107.068V148.272C749.975 149.307 750.819 150.151 751.854 150.151H764.014C765.049 150.151 765.893 150.994 765.893 152.029V182.378C765.893 183.413 765.049 184.257 764.014 184.257H697.298C696.263 184.257 695.419 183.413 695.419 182.378V107.077C695.419 102.684 694.471 99.4831 692.575 97.4738C690.679 95.4645 688.182 94.4642 685.077 94.4642C682.502 94.4642 679.945 95.221 677.405 96.7345C675.022 98.161 672.995 100.075 671.316 102.493C671.099 102.806 670.986 103.189 670.986 103.571V148.28C670.986 149.316 671.83 150.159 672.865 150.159H679.336C680.371 150.159 681.215 151.003 681.215 152.038V182.386C681.215 183.421 680.371 184.265 679.336 184.265L618.3 184.283L618.283 184.274Z" fill="#E3E2E0" />
            <path d="M835.929 184.269C834.894 184.269 834.05 183.426 834.05 182.39V145.04V44.3836C834.05 43.3485 833.207 42.5048 832.172 42.5048H817.741C816.706 42.5048 815.862 41.6611 815.862 40.626V5.1718C815.862 4.1367 816.706 3.29297 817.741 3.29297H913.5C938.204 3.29297 956.844 8.08572 969.422 17.6712C981.999 27.2567 988.288 41.6002 988.288 60.6929C988.288 79.7856 982.034 93.181 969.535 102.697C957.027 112.204 938.734 116.962 914.631 116.962H893.66C892.625 116.962 891.781 117.806 891.781 118.841V148.38C891.781 149.415 892.625 150.259 893.66 150.259H922.868C923.904 150.259 924.747 151.103 924.747 152.138V182.373C924.747 183.408 923.904 184.252 922.868 184.252L835.921 184.269H835.929ZM906.907 41.374H893.668C892.633 41.374 891.79 42.2178 891.79 43.2528V80.4206C891.79 81.4557 892.633 82.2994 893.668 82.2994H905.768C913.5 82.2994 919.294 80.6728 923.156 77.411C927.018 74.1491 928.949 68.965 928.949 61.8411C928.949 54.7172 927.07 49.4373 923.321 46.2103C919.572 42.9919 914.092 41.3827 906.898 41.3827L906.907 41.374Z" fill="#E3E2E0" />
            <path d="M995.527 184.274C994.492 184.274 993.648 183.431 993.648 182.396V150.16V45.3542V14.2144C993.648 13.2489 994.379 12.4487 995.336 12.3443L1046.14 7.14271C1047.25 7.02964 1048.21 7.89946 1048.21 9.01284V148.272C1048.21 149.307 1049.06 150.151 1050.09 150.151H1063.96C1064.99 150.151 1065.84 150.995 1065.84 152.03V182.378C1065.84 183.413 1064.99 184.257 1063.96 184.257L995.536 184.274H995.527Z" fill="#E3E2E0" />
            <path d="M1122.61 186.409C1094.56 186.409 1080.55 170.648 1080.55 139.126V99.8529C1080.55 98.8178 1079.71 97.9741 1078.67 97.9741H1066.51C1065.48 97.9741 1064.63 97.1304 1064.63 96.0953V68.6522C1064.63 67.6867 1065.37 66.8778 1066.33 66.7821L1133.05 60.4323C1134.15 60.328 1135.1 61.1978 1135.1 62.3025V139.239C1135.1 143.858 1136.12 147.198 1138.17 149.242C1140.21 151.286 1142.83 152.312 1146.01 152.312C1151.56 152.312 1155.87 149.537 1158.97 143.997C1159.12 143.718 1159.2 143.396 1159.2 143.075V99.8616C1159.2 98.8265 1158.36 97.9828 1157.32 97.9828H1150.85C1149.82 97.9828 1148.97 97.1391 1148.97 96.104V68.6435C1148.97 67.678 1149.7 66.8777 1150.66 66.7734L1211.7 60.4584C1212.8 60.3453 1213.77 61.2152 1213.77 62.3286V148.276C1213.77 149.311 1214.61 150.155 1215.64 150.155H1227.8C1228.84 150.155 1229.68 150.999 1229.68 152.034V182.382C1229.68 183.417 1228.84 184.261 1227.8 184.261H1162.22C1161.18 184.261 1160.34 183.417 1160.34 182.382V175.406C1160.34 173.78 1158.43 172.936 1157.21 174.014C1153.37 177.433 1149.02 180.199 1144.14 182.33C1137.89 185.061 1130.71 186.418 1122.61 186.418V186.409Z" fill="#E3E2E0" />
            <path d="M1309.5 186.297C1301.77 186.297 1294.27 185.384 1287 183.566C1280.49 181.94 1274.65 179.678 1269.48 176.782C1268.51 176.234 1267.28 176.686 1266.85 177.712L1264.63 183.088C1264.34 183.792 1263.65 184.245 1262.89 184.245H1241.02C1239.98 184.245 1239.14 183.401 1239.14 182.366V143.38C1239.14 142.345 1239.98 141.501 1241.02 141.501H1266.86C1267.51 141.962 1267.98 142.293 1268.64 142.745C1270.43 148.069 1273.32 152.313 1277.33 155.479C1281.66 158.889 1286.35 160.594 1291.42 160.594C1298.1 160.594 1301.43 158.089 1301.43 153.096C1301.43 149.46 1299.93 146.659 1296.94 144.685C1293.95 142.719 1290.08 141.049 1285.34 139.683C1280.6 138.318 1275.59 136.839 1270.28 135.247C1264.97 133.655 1259.95 131.481 1255.22 128.715C1250.48 125.949 1246.62 122.165 1243.63 117.346C1240.64 112.536 1239.14 106.23 1239.14 98.4187C1239.14 91.3731 1241.02 84.9712 1244.77 79.2129C1248.52 73.4547 1254.21 68.8533 1261.82 65.4001C1269.44 61.9556 1279 60.2246 1290.52 60.2246C1297.95 60.2246 1304.82 60.9988 1311.15 62.5557C1316.75 63.9301 1322.05 66.0351 1327.03 68.8707C1328.04 69.4448 1329.31 68.9577 1329.71 67.8617L1331.22 63.6256C1331.49 62.8776 1332.19 62.3818 1332.99 62.3818H1354.79C1355.83 62.3818 1356.67 63.2255 1356.67 64.2606V99.6017C1356.67 100.637 1355.83 101.481 1354.79 101.481H1328.91C1328.13 101.481 1327.41 101.002 1327.13 100.271C1325.19 95.1308 1322.51 91.3036 1319.09 88.8071C1315.41 86.1194 1311.46 84.7711 1307.21 84.7711C1299.78 84.7711 1296.07 87.0414 1296.07 91.5906C1296.07 94.7742 1297.6 97.2967 1300.67 99.1494C1303.74 101.011 1307.68 102.62 1312.49 103.977C1317.3 105.343 1322.4 106.9 1327.78 108.639C1333.16 110.379 1338.26 112.727 1343.08 115.685C1347.89 118.642 1351.83 122.583 1354.9 127.506C1357.97 132.429 1359.5 138.831 1359.5 146.712C1359.5 158.759 1355.16 168.362 1346.49 175.529C1337.8 182.688 1325.47 186.271 1309.48 186.271L1309.5 186.297Z" fill="#E3E2E0" />
        </svg>

    )
}

export const style = () => {
    return;
}

export const buttonstyle = {
    color: "#02BD36",
    border: "solid 1px",
    textTransform: "none",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    padding: "11px 19px !important",
    "&:hover": {
        color: "#02BD36",
        backgroundColor: "unset"
    },
}

export const buttonstyle2 = {
    color: "#02BD36",
    border: "solid 1px",
    textTransform: "none",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    marginLeft: "20px",
    padding: "11px 19px !important",
    "&:hover": {
        color: "#02BD36",
        backgroundColor: "unset"
    },
}

export const buttonstyle7 = {
    color: "#02BD36",
    border: "solid 1px",
    textTransform: "none",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    marginLeft: "20px",
    minWidth: "91px",
    padding: "11px 19px !important",
    "&:hover": {
        color: "#02BD36",
        backgroundColor: "unset"
    },
}

export const buttonstyle3 = {
    backgroundColor: "#FBFBFB",
    minWidth: "420px !important",
    fontSize: "12px",
    minHeight: "48px",
    color: "#525A65",
    borderRadius: " 20px",
    background: "linear-gradient(#FBFBFB, #FBFBFB) padding-box, linear-gradient(to right, #02BD36 0%, #A080E8 35%, #E67B02 65%, #2998E9 100%) border-box",
    border: "1px solid transparent",
    textTransform: "none"
}

export const buttonstyle4 = {
    color: "#A080E8",
    border: "solid 1px",
    textTransform: "none",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    marginLeft: "20px",
    backgroundColor: "#46245D",
    minHeight: "49px",
    minWidth: "130px !important",
    padding: "11px 19px !important",
    "&:hover": {
        color: "#A080E8",
        backgroundColor: "unset"
    },
}


export const buttonstyle5 = {
    color: "#351E06",
    border: "solid 1px",
    textTransform: "none",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    marginLeft: "20px",
    backgroundColor: "#F5F5F5",
    minHeight: "44px",
    minWidth: "241px !important",
    padding: "11px 19px !important",
    "&:hover": {
        color: "#351E06",
        backgroundColor: "#F5F5F5"
    },
}

export const buttonstyle6 = {
    color: "#2998E9",
    border: "solid 1px",
    textTransform: "none",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    marginLeft: "20px",
    backgroundColor: "#043A8D",
    minHeight: "49px",
    minWidth: "97px !important",
    padding: "11px 19px !important",
    "&:hover": {
        color: "#2998E9",
        backgroundColor: "#043A8D"
    },
}