import { Delete, Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { sportsEmojis } from "../../routes/OnboardingForms/constants";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { upsertOnboardingForm } from "../../services/query";
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Textarea from '@mui/joy/Textarea';


export default function OnboardingFormModal({
  open,
  handleClose,
  onSuccess,
  formData,
}) {
  const [loading, setLoading] = useState(false);
  const [newFields, setNewFields] = useState([]);
  const [formName, setFormName] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [emoji, setEmoji] = useState(sportsEmojis[0]);

  useEffect(() => {
    setNewFields(formData.custom_questions || []);
    setFormName(formData.title || "");
    setWelcomeMessage(formData.welcome_message || "");
    setEmoji(formData.icon || sportsEmojis[0]);
  }, [formData]);

  const { user } = useAuth();
  const supabase = useSupabase();

  const addField = () => {
    setNewFields([...newFields, { name: "", type: "text" }]);
  };

  const removeField = (index) => {
    setNewFields(newFields.filter((_, i) => i !== index));
  };

  const setFieldName = (index, name) => {
    setNewFields(
      newFields.map((field, i) => (i === index ? { ...field, name } : field))
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth keepMounted={false} sx={{

      ".MuiPaper-root": {
        borderRadius: "24px",
        width: "529px",
        minHeight: "525px",
        backgroundColor: "#FBFBFB",
      },
    }}>
      <Box sx={{ padding: "40px 23px 16px 23px", minHeight: "525px", maxHeight: "670px" }}>
        <DialogTitle sx={{ color: "#000000", fontWeight: "600 !important", fontSize: "16px", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {!formData.title
                ? "Create a new form"
                : `Edit ${formData.title}`}
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
              <Button onClick={handleClose} startIcon={<CloseIcon />} size="small" sx={close}></Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, fontSize: "14px", color: "#000000", fontWeight: "400 !important", paddingTop: "10px", paddingBottom: "10px" }}>
            Design a form tailored to your client’s needs. Add a name for your form, include a welcome message, and add any questions you’d like your clients to answer
          </DialogContentText>
          <Box sx={{ mb: 3 }} >

            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Form name
            </InputLabel>
            <TextField
              value={formName}
              variant="outlined"
              placeholder="Form name"

              onChange={(e) => setFormName(e.target.value)}
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "0px solid #DAD9D8"
                },
                ".MuiInputBase-input": {
                  fontSize: "12px",
                  padding: "14px 24px",
                  color: "#525A65",
                  fontWeight: "400 !important"
                }
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 3 }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Welcome message
            </InputLabel>
            <TextField
              variant="outlined"
              type="textarea"
              value={welcomeMessage}
              placeholder="Type here..."
              onChange={(e) => setWelcomeMessage(e.target.value)}
              minRows={3}
              multiline
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "0px solid #DAD9D8"
                },
                ".MuiInputBase-input": {
                  fontSize: "12px",
                  padding: "0px 10px",
                  color: "#525A65",
                  fontWeight: "400 !important"
                }
              }}
              fullWidth
            />
          </Box>

          {/* <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 2, mr: 2, flexGrow: 1 }}>
              Pick an emoji: <span style={{ fontSize: 20 }}>{emoji}</span>
            </Typography>
            <Box flexGrow={0}>
              {sportsEmojis.map((sport, index) => (
                <Button
                  key={index}
                  sx={{
                    width: 40,
                    minWidth: 40,
                    height: 40,
                    fontSize: 20,
                    p: 0,
                  }}
                  variant={emoji === sport ? "outlined" : "text"}
                  onClick={() => setEmoji(sport)}
                >
                  {sport}
                </Button>
              ))}
            </Box> */}
          {/* </Box> */}
          {newFields.length > 0 && (
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99"
            }}>
              Custom field
            </InputLabel>
          )}
          {newFields.map((field, index) => (
            <Box display="flex" sx={{ mb: 3 }} key={index}>
              <TextField
                value={field.name}
                variant="outlined"
                placeholder="Type your question here..."
                onChange={(e) => setFieldName(index, e.target.value)}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "20px",
                    border: "0px solid #DAD9D8"
                  },
                  ".MuiInputBase-input": {
                    fontSize: "12px",
                    padding: "14px 24px",
                    color: "#525A65",
                    fontWeight: "400 !important"
                  }
                }}
                fullWidth
              />
              <IconButton onClick={() => removeField(index)}>
                <Delete />
              </IconButton>
            </Box>
          ))}
        </DialogContent>
        <Box sx={{ paddingLeft: "23px !important" }}>
          <Button startIcon={<Add />} size="small" onClick={addField} sx={addnewquestionstyle}>
            Add a new question
          </Button>
        </Box>
        <DialogActions>
          <Button
            disabled={loading}
            sx={createupdatestyle}
            onClick={async () => {
              setLoading(true);

              const id = formData.id ? { id: formData.id } : {};
              const payload = {
                ...id,
                user_id: user.id,
                custom_questions: newFields,
                title: formName,
                welcome_message: welcomeMessage,
                icon: emoji,
              };

              const { data } = await upsertOnboardingForm(supabase, payload);
              setLoading(false);
              onSuccess(data[0]);
            }}
          >
            {!formData.title ? "Create" : "Update"}
          </Button>
        </DialogActions>
      </Box >
    </Dialog>
  );
}


export const addnewquestionstyle = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "30px",
  fontSize: "12px",
  ".MuiSvgIcon-root": {
    fontSize: "12px !important",
    paddingLeft: "2px",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}

export const createupdatestyle = {
  marginLeft: "auto",
  color: "#2998E9",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  marginLeft: "auto",
  fontSize: "13px",
  padding: "11px 8px !important",
  minWidth: "96px !important",
  "&:hover": {
    color: "#2998E9",
    backgroundColor: "unset"
  },
}

export const close = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  float: "right",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  backgroundColor: "#EEEEEE",
  ".MuiSvgIcon-root": {
    fontSize: "18px !important",
    paddingLeft: "0px",
    color: "#525A65"
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}