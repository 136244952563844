// COACH
// COACH
// COACH

// id from session
// q-coach-get
export const getCoachById = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-coach-get", {
      body: {
        id,
      },
    })
  ).data;

// id from session
// q-coach-update
export const updateCoach = async (supabase, user, user_id) =>
  (
    await supabase.functions.invoke("q-coach-update", {
      body: user,
    })
  ).data;

// id from session
// q-coach-reset
export const resetCoach = async (supabase, user_id) =>
  (
    await supabase.functions.invoke("q-coach-reset", {
      body: {
        user_id,
      },
    })
  ).data;

// ATHLETE
// ATHLETE
// ATHLETE
// only athletes accessible by coach id from session
// q-athlete-get
export const getAthleteByEmail = async (supabase, email) =>
  (
    await supabase.functions.invoke("q-athlete-get", {
      body: {
        email,
      },
    })
  ).data;

// coach id from session
// q-athlete-by-coach
export const getAthletesByCoachId = async (supabase, coach_id) =>
  (await supabase.functions.invoke("q-athlete-by-coach")).data;

// filter coach id from session
// q-athlete-profile
export const getAthleteProfile = async (supabase, athlete_id) =>
  (
    await supabase.functions.invoke("q-athlete-profile", {
      body: {
        athlete_id,
      },
    })
  ).data;

// with coach_id from session
// q-athlete-insert
export const insertAthlete = async (supabase, athlete) =>
  (
    await supabase.functions.invoke("q-athlete-insert", {
      body: athlete,
    })
  ).data;

// with coach_id from session
// q-athlete-upsert
export const upsertAthlete = async (supabase, athlete) =>
  (
    await supabase.functions.invoke("q-athlete-upsert", {
      body: athlete,
    })
  ).data;

// COACH ATHLETE
// COACH ATHLETE
// COACH ATHLETE
// filter coach_id from session
// q-ca-get
export const getCoachAthlete = async (supabase, coach_id, athlete_id) =>
  (
    await supabase.functions.invoke("q-ca-get", {
      body: {
        coach_id,
        athlete_id,
      },
    })
  ).data;

//  coach_id from session
// q-ca-insert
export const insertCoachAthlete = async (supabase, coach_athlete) =>
  (
    await supabase.functions.invoke("q-ca-insert", {
      body: coach_athlete[0],
    })
  ).data;

// OBFORM
// OBFORM
// OBFORM
//  filter coach_id (user_id) from session
// q-obf-get
export const getOnboardingFormsByUserId = async (supabase, user_id) =>
  (
    await supabase.functions.invoke("q-obf-get", {
      body: {
        user_id,
      },
    })
  ).data;

// filter coach_id from session
// q-obf-upsert
export const upsertOnboardingForm = async (supabase, onboarding_form) =>
  (
    await supabase.functions.invoke("q-obf-upsert", {
      body: onboarding_form,
    })
  ).data;

// filter coach_id from session
// q-obf-delete
export const deleteOnboardingForm = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-obf-delete", {
      body: {
        id,
      },
    })
  ).data;

//  OBFORM RESPONSE
//  OBFORM RESPONSE
//  OBFORM RESPONSE
// q-obfres-p-get
export const getOnboardingFormResponseById = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-obfres-p-get", {
      body: {
        id,
      },
    })
  ).data;

// filter coach_id from session
// q-obfres-insert
export const insertOnboardingFormResponse = async (
  supabase,
  onboarding_form_response
) =>
  (
    await supabase.functions.invoke("q-obfres-insert", {
      body: onboarding_form_response,
    })
  ).data;

// public method no session
// q-obfres-p-upsert
export const upsertOnboardingFormResponse = async (
  supabase,
  onboarding_form_response
) =>
  (
    await supabase.functions.invoke("q-obfres-p-upsert", {
      body: onboarding_form_response,
    })
  ).data;

// TRAINING PLANS
// TRAINING PLANS
// TRAINING PLANS
// public display
// q-plan-p-get
export const getPlanByIdPublic = async (supabase, id, email) =>
  (
    await supabase.functions.invoke("q-plan-p-get", {
      body: {
        id,
        email,
      },
    })
  ).data;

// filter coach_id from session
// q-plan-get-by-coach
export const getPlansByCoachId = async (supabase, coach_id) =>
  (
    await supabase.functions.invoke("q-plan-get-by-coach", {
      body: {
        coach_id,
      },
    })
  ).data;

// filter coach_id from session
// q-plan-upsert
export const upsertPlan = async (supabase, plan) =>
  (
    await supabase.functions.invoke("q-plan-upsert", {
      body: plan,
    })
  ).data;

// filter coach_id from session
// q-plan-delete
export const deletePlan = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-plan-delete", {
      body: {
        id,
      },
    })
  ).data;

// filter coach_id from session
// q-exercise-by-coach
export const getExercisesByCoachId = async (supabase, coach_id) =>
  (
    await supabase.functions.invoke("q-exercise-by-coach", {
      body: {
        coach_id,
      },
    })
  ).data;

// filter coach_id from session
// q-exercise-upsert
export const upsertExercise = async (supabase, exercise) =>
  (
    await supabase.functions.invoke("q-exercise-upsert", {
      body: exercise,
    })
  ).data;

// filter coach_id from session
// q-exercise-delete
export const deleteExercise = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-exercise-delete", {
      body: {
        id,
      },
    })
  ).data;

// filter coach_id from session
// q-ep-insert
export const insertExercisePlans = async (supabase, exercise_plan) =>
  (
    await supabase.functions.invoke("q-ep-insert", {
      body: exercise_plan,
    })
  ).data;

// filter coach_id from session
// q-ep-delete
export const deleteExercisePlans = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-ep-delete", {
      body: {
        id,
      },
    })
  ).data;

// CALENDARS
// CALENDARS
// CALENDARS
// filter coach_id from session
// q-cal-get-by-coach
export const getCalendarsByCoachId = async (supabase, coach_id) =>
  (
    await supabase.functions.invoke("q-cal-get-by-coach", {
      body: {
        coach_id,
      },
    })
  ).data;

// filter coach_id from session
// q-cal-get-by-coach-athlete
export const getCalendarsByCoachIdAthleteId = async (
  supabase,
  coach_id,
  athlete_id
) =>
  (
    await supabase.functions.invoke("q-cal-get-by-coach-athlete", {
      body: {
        coach_id,
        athlete_id,
      },
    })
  ).data;
// filter coach_id from session
// q-cal-toggle
export const toggleCalendar = async (supabase, id, enabled) =>
  (
    await supabase.functions.invoke("q-cal-toggle", {
      body: {
        id,
        enabled,
      },
    })
  ).data;

// q-cal-insert
export const insertCalendar = async (supabase, calendar) =>
  (
    await supabase.functions.invoke("q-cal-insert", {
      body: calendar,
    })
  ).data;

// EVENTS
// EVENTS
// EVENTS
// filter coach_id from session
// q-events-insert
export const insertEvent = async (supabase, event) =>
  (
    await supabase.functions.invoke("q-events-insert", {
      body: event,
    })
  ).data;

// filter coach_id from session
// q-events-delete
export const deleteSBEvent = async (supabase, id) =>
  (
    await supabase.functions.invoke("q-events-delete", {
      body: {
        id,
      },
    })
  ).data;

// Delete athlete
// q-delete-athlete
export const deleteAthlete = async (supabase, athlete_id) =>
  await supabase.functions.invoke("q-athlete-delete", {
    body: {
      athlete_id,
    },
  });

export const loops_send_sign_in = async (supabase, email, CoachName) =>
  (
    await supabase.functions.invoke("loops-resend", {
      body: {
        email: email,
        CoachName: CoachName,
      },
    })
  ).data;

export const loops_send_form = async (supabase, email, clientname, CoachName) =>
  (
    await supabase.functions.invoke("loops-resend-invite", {
      body: {
        email: email,
        clientname: clientname,
        CoachName: CoachName,
      },
    })
  ).data;

export const get_user_onboarding_form_response = async (supabase, id) =>
  (
    await supabase.functions.invoke("get-user-formresponse", {
      body: {
        onboarding_form_response: id,
      },
    })
  ).data;

export const get_fill_exercise = async (supabase) =>
  (await supabase.functions.invoke("get_fill_exercise")).data;

export const get_fill_exercise_id = async (supabase, id) =>
  (
    await supabase.functions.invoke("get_fill_exercis_id", {
      body: {
        id: id,
      },
    })
  ).data;
