import {
  Box,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Autocomplete
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { Navigate } from "react-router-dom";
import { updateCoach } from "../../services/query";
import { trimAndValidatePhone } from "../../utils/validations";
import { loops_send_sign_in } from "../../services/query";
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import dayjs from 'dayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { sortedCountries } from "../../components/countrycode/countrycode"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputAdornment from "@mui/material/InputAdornment";
import Grid from '@mui/material/Grid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';



const steps = [
  "Personal Information",
  "Location & Contact",
  "Coaching Experience",
];


export const CoachOnboardingComponent = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { control, register, handleSubmit } = useForm();
  const { sessionUser, syncUser, session, user } = useAuth();
  const [area_code, setArea_code] = React.useState("");
  const [fill, setFill] = React.useState({ step2: "#CECECE", step3: "#CECECE" })
  const supabase = useSupabase();
  const stepIcons = {
    step1: <Stepicon />,
    step2: <Stepicon2 fill={fill[`step2`]} />,
    step3: <Stepicon3 fill={fill[`step3`]} />,
  }
  const onSubmit = handleSubmit(async (data) => {
    console.log(`${area_code}${data.phone_number}`)
    const trimedPhoneNumber = trimAndValidatePhone(
      `${area_code}${data.phone_number}`
    );
    if (!trimedPhoneNumber) {
      return alert(
        "Please enter a valid phone number consisting of digits only"
      );
    }
    const st_payload = {
      email: sessionUser.email,
      name: `${data.first_name} ${data.last_name}`,
      metadata: {
        cp_id: sessionUser.id,
      },
    };


    const { data: st_user } = await supabase.functions.invoke(
      "st-create-user",
      {
        body: st_payload,
      }
    );

    const st_customer_id = st_user?.customer.id;
    await loops_send_sign_in(supabase, sessionUser.email, `${data.first_name}`);

    const payload = {
      ...data,
      phone_number: trimedPhoneNumber,
      email: sessionUser.email,
      status: "ACTIVE",
      onboarded_at: new Date().toISOString(),
      full_name: `${data.first_name} ${data.last_name}`,
      st_customer_id,
    };
    delete payload.area_code;

    await updateCoach(supabase, payload, sessionUser.id);

    await syncUser(session);
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 0) {
      setFill((prevFill) => ({ ...prevFill, step2: "#525A65" }));
    } else if (activeStep === 1) {
      setFill((prevFill) => ({ ...prevFill, step3: "#525A65" }));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 1) {
      setFill((prevFill) => ({ ...prevFill, step2: "#CECECE" }));
    } else if (activeStep === 2) {
      setFill((prevFill) => ({ ...prevFill, step3: "#CECECE" }));
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setFill({ step2: "#CECECE", step3: "#CECECE" });
  };

  if (user && user.onboarded_at) {
    return <Navigate to="/" />;
  }

  return (
    <PageContainer>
      <Paper variant="outlined" sx={{ display: "flex", justifyContent: "center", border: "none", background: "none" }}>
        <Box sx={{ width: "529px", p: "27px", height: "600px", borderRadius: "24px", backgroundColor: "#FBFBFB", marginTop: "8%" }}>
          <Typography variant="subtitle" sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "24px" }} >Welcome to Coach Plus</Typography>
          <br />
          <Typography variant="subtitle" sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "10px" }} >Less time on admin, more time with your clients!</Typography>
          <Stepper activeStep={activeStep} sx={{
            mb: 4, mt: 3, ".css-52tf1x": { marginLeft: "10.5px !important" },
            ".css-vgb7rt": {
              borderWidth: "2px",
              borderColor: "#CECECE !important",
            },
            ".MuiStepConnector-root MuiStepConnector-vertical Mui-active css-52tf1x": {
              ".css-vgb7rt": {
                borderColor: "#525A65 !important",
              }
            },
            ".MuiStepConnector-root MuiStepConnector-vertical Mui-completed css-52tf1x": {
              ".css-vgb7rt": {
                borderColor: "#525A65 !important",

              }
            },
          }} orientation="vertical">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel sx={{ padding: "0px" }} icon={stepIcons[`step${index + 1}`] || <Stepicon />}>
                    <Typography
                      variant="subtitle"
                      sx={{
                        fontSize: { xs: 12, sm: 16 },
                        color: index <= activeStep ? "#525A65" : "#CECECE",
                        fontWeight: "400 !important"
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <>
              <form onSubmit={onSubmit}>
                {/* <Typography sx={{ mb: 3 }} variant="h6">
                  {steps[activeStep]}
                </Typography> */}
                {activeStep === 0 && (
                  <PersonalInfo control={control} register={register} />
                )}
                {activeStep === 1 && (
                  <LocationAndContact
                    email={sessionUser.email}
                    register={register}
                    control={control}
                    area_code={setArea_code}
                  />
                )}
                {activeStep === 2 && <Experience register={register} />}
              </form>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={backstyle}
                >
                  Back
                </Button>
                {/* <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button> */}
                <Box sx={{ flex: "1 1 auto" }} />

                <Button
                  sx={nextstyle}
                  onClick={
                    activeStep === steps.length - 1 ? onSubmit : handleNext
                  }
                >
                  {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
                  Next
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </PageContainer>
  );
};

const PersonalInfo = ({ register, control }) => (
  <>
    <Box sx={{ mb: 3 }}>
      {/* <TextField {...register("first_name")} label="First Name" fullWidth /> */}
      <InputLabel shrink htmlFor="bootstrap-input" sx={{
        paddingLeft: "24px",
        color: "#838F99",
        fontSize: "16px",
        fontWeight: "400 !important"
      }}>
        First Name
      </InputLabel>
      <TextField
        {...register("first_name")}
        variant="outlined"
        placeholder="First Name"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "20px",
            border: "0px solid #DAD9D8"
          },
          ".MuiInputBase-input": {
            fontSize: "12px",
            padding: "14px 24px",
            color: "#525A65",
            fontWeight: "400 !important"
          }
        }}
        fullWidth
      />
    </Box>
    <Box sx={{ mb: 3 }}>
      {/* <TextField {...register("last_name")} label="Last Name" fullWidth /> */}
      <TextField
        {...register("last_name")}
        variant="outlined"
        placeholder="Last Name"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "20px",
            border: "0px solid #DAD9D8"
          },
          ".MuiInputBase-input": {
            fontSize: "12px",
            padding: "14px 24px",
            color: "#525A65",
            fontWeight: "400 !important"
          }
        }}
        fullWidth
      />
    </Box>
    <Box sx={{ mb: 3 }}>
      <Controller
        control={control}
        name="dob"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateField']} sx={{
                ".css-10o2lyd-MuiStack-root": {
                  paddingTop: "0px !important",
                },
              }}>
                <Box sx={{ mt: 3, width: "100%" }}>
                  <DateField
                    defaultValue={dayjs(new Date(field.value ? field.value : null))}
                    format="LL"
                    sx={formDateFieldStyle}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    fullWidth
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          );
        }}
      />
      {/* <Controller
        control={control}
        name="dob"
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <MobileDatePicker
              label="Date of Birth"
              value={field.value}
              inputRef={field.ref}
              onChange={(date) => {
                field.onChange(date);
              }}
              fullWidth
            />
          );
        }}
      /> */}
    </Box>
  </>
);

const LocationAndContact = ({ register, email, control, area_code }) => (
  <>
    <Box sx={{ mb: 3 }}>
      {/* <TextField {...register("city")} label="City" fullWidth /> */}
      <InputLabel shrink htmlFor="bootstrap-input" sx={{
        paddingLeft: "24px",
        color: "#838F99",
        fontSize: "16px",
        fontWeight: "400 !important"
      }}>
        City
      </InputLabel>
      <TextField
        {...register("city")}
        variant="outlined"
        placeholder="First Name"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "20px",
            border: "0px solid #DAD9D8"
          },
          ".MuiInputBase-input": {
            fontSize: "12px",
            padding: "14px 24px",
            color: "#525A65",
            fontWeight: "400 !important"
          }
        }}
        fullWidth
      />
    </Box>
    <Box sx={{ mb: 3 }}>
      {/* <TextField {...register("country")} label="Country" fullWidth /> */}
      <TextField
        {...register("country")}
        variant="outlined"
        placeholder="Country"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "20px",
            border: "0px solid #DAD9D8"
          },
          ".MuiInputBase-input": {
            fontSize: "12px",
            padding: "14px 24px",
            color: "#525A65",
            fontWeight: "400 !important"
          }
        }}
        fullWidth
      />
    </Box>

    <Box sx={{ mb: 3 }} display="flex" alignItems="center">
      {/* <Typography sx={{ mr: 1 }}>+</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ paddingRight: "16px !important" }}>
          <Controller
            name="country_code"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={sortedCountries}
                getOptionLabel={(option) => `+(${option.phone})`}
                onChange={(event, value) => {
                  field.onChange(value);
                  area_code(value.phone)
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Code" sx={{
                    ".css-vubbuv": {
                      display: "none"
                    },
                    "&:hover": {
                      ".MuiInputAdornment-root": {
                        display: "none"
                      }
                    },
                    "&:focus": {
                      ".MuiInputAdornment-root": {
                        display: "none"
                      }
                    },
                    "&:active": {
                      ".MuiInputAdornment-root": {
                        display: "none"
                      }
                    }
                  }} InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="end">
                        <KeyboardArrowDownIcon sx={{
                          display: "inline-block", position: "absolute", marginLeft: "80px", ":hover": {
                            display: "none"
                          }
                        }} />
                      </InputAdornment>
                    )
                  }} />
                )}
                sx={formautodStyle1}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={8} sx={{
          paddingLeft: "0px !important",
          ".css-cmlpbm": {
            widht: "100% !important"
          },
          ".MuiFormControl-root": {
            widht: "100% !important"
          }

        }}>
          <TextField
            type="number"
            placeholder="Telephone number"
            {...register("phone_number")}
            sx={formautodStyle2}
            required
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      {/* <TextField
        {...register("area_code")}
        label="Country Code"
        type="number"
        fullWidth
        sx={{ mr: 1, width: 120 }}
      />
      <TextField
        {...register("phone_number")}
        label="Phone Number"
        type="number"
        fullWidth
      /> */}
    </Box>
    {/* <Box sx={{ mb: 3 }}>
      <TextField
        value={email}
        label="Email"
        fullWidth
        InputProps={{
          readOnly: true,
        }}
      />
    </Box> */}
  </>
);

const Experience = ({ register }) => (
  <>
    <Box sx={{ mb: 3 }}>
      {/* <TextField
        {...register("sports_activities")}
        label="What sport or activity do you coach?"
        fullWidth
      /> */}
      <TextField
        {...register("sports_activities")}
        variant="outlined"
        placeholder="What sport or activity do you coach?"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "20px",
            border: "0px solid #DAD9D8"
          },
          ".MuiInputBase-input": {
            fontSize: "12px",
            padding: "14px 24px",
            color: "#525A65",
            fontWeight: "400 !important"
          }
        }}
        fullWidth
      />
    </Box>
    {/* <Box sx={{ mb: 3 }}>
      <TextField
        {...register("athlete_types")}
        label="What types of athletes do you coach?"
        helperText="e.g., amateur, professional, older adults"
        fullWidth
      />
    </Box> */}
    <Box sx={{ mb: 16 }}>
      <TextField
        {...register("athlete_count")}
        variant="outlined"
        type="number"
        placeholder="How many athletes are you currently coach?"
        sx={{
          "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          ".MuiInputBase-root": {
            borderRadius: "20px",
            border: "0px solid #DAD9D8"
          },
          ".MuiInputBase-input": {
            fontSize: "12px",
            padding: "14px 24px",
            color: "#525A65",
            fontWeight: "400 !important"
          }
        }}
        fullWidth
      />
      {/* <TextField
        {...register("athlete_count")}
        label="How many athletes do you currently coach?"
        type="number"
        fullWidth
      /> */}
    </Box>
  </>
);




export const backstyle = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  marginLeft: "auto",
  fontSize: "13px",
  backgroundColor: "FBFBFB",
  maxWidth: "96px",
  minHeight: "48",
  padding: "11px 8px !important",
  minWidth: "96px !important",
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset"
  },
}

export const nextstyle = {
  marginLeft: "auto",
  color: "#02BD36",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  marginLeft: "auto",
  fontSize: "13px",
  backgroundColor: "FBFBFB",
  maxWidth: "96px",
  minHeight: "48",
  padding: "11px 8px !important",
  minWidth: "96px !important",
  "&:hover": {
    color: "#02BD36",
    backgroundColor: "unset"
  },
}

export const formDateFieldStyle = {
  ".css-10o2lyd-MuiStack-root": {
    paddingTop: "0px !important",
  },
  ".MuiInputBase-root": {
    borderRadius: "20px",
    border: "0px solid #DAD9D8"
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "16px  24px",
    color: "#525A65",
    fontWeight: "400 !important"
  }
}


export const formautodStyle1 = {
  width: "100%",
  ".css-feqhe6": {
    paddingRight: "8px !important"
  },
  ".MuiInputBase-root": {
    borderRadius: "20px",
    padding: "7px",
    border: "0px solid #DAD9D8"
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "7.5px 5px 7.5px 18px !important",
    color: "#525A65",
    fontWeight: "400 !important"
  }
}

export const formautodStyle2 = {
  mr: 1,
  "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  ".MuiInputBase-root": {
    borderRadius: "20px",
    maxHeight: "45.5px",
    border: "0px solid #DAD9D8"
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "14 24px",
    color: "#525A65",
    fontWeight: "400 !important"
  }
}

export const Stepicon = () => {
  return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5Z" fill="#525A65" />
    <path d="M10.792 15V8.42H9.434V6.866C9.87267 6.866 10.232 6.79133 10.512 6.642C10.8013 6.49267 10.988 6.19867 11.072 5.76H12.57V15H10.792Z" fill="#FBFBFB" />
  </svg>)
}




export const Stepicon2 = ({ fill }) => {
  return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5Z" fill={fill} />
    <path d="M7.63 15V13.124L11.116 10.716C11.2933 10.5853 11.4753 10.4453 11.662 10.296C11.8487 10.1373 12.0213 9.96 12.18 9.764C12.3387 9.568 12.4693 9.358 12.572 9.134C12.6747 8.90067 12.726 8.65333 12.726 8.392C12.726 8.112 12.6607 7.874 12.53 7.678C12.4087 7.482 12.2267 7.328 11.984 7.216C11.7507 7.104 11.4613 7.048 11.116 7.048C10.808 7.048 10.542 7.09467 10.318 7.188C10.1033 7.28133 9.92133 7.42133 9.772 7.608C9.632 7.78533 9.52933 8.00933 9.464 8.28C9.39867 8.55067 9.366 8.86333 9.366 9.218L7.672 8.882C7.65333 8.20067 7.77467 7.61267 8.036 7.118C8.30667 6.614 8.70333 6.22667 9.226 5.956C9.74867 5.676 10.388 5.536 11.144 5.536C11.8067 5.536 12.3853 5.64333 12.88 5.858C13.384 6.07267 13.776 6.38067 14.056 6.782C14.336 7.18333 14.476 7.678 14.476 8.266C14.476 8.72333 14.3967 9.13867 14.238 9.512C14.0887 9.876 13.8927 10.2073 13.65 10.506C13.4073 10.8047 13.1413 11.0707 12.852 11.304C12.5627 11.528 12.2827 11.7287 12.012 11.906L9.66 13.488V13.6L14.644 13.53V15H7.63Z" fill="#FBFBFB" />
  </svg>)
}

export const Stepicon3 = ({ fill }) => {
  return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5Z" fill={fill} />
    <path d="M10.99 15.196C10.3927 15.196 9.87467 15.1307 9.436 15C8.99733 14.8787 8.62867 14.706 8.33 14.482C8.04067 14.2487 7.81667 13.964 7.658 13.628C7.49933 13.292 7.40133 12.9187 7.364 12.508L8.988 12.004C8.99733 12.3307 9.058 12.606 9.17 12.83C9.282 13.054 9.43133 13.2407 9.618 13.39C9.80467 13.53 10.0193 13.6327 10.262 13.698C10.5047 13.7633 10.7567 13.796 11.018 13.796C11.4007 13.796 11.7413 13.7447 12.04 13.642C12.3387 13.53 12.5767 13.362 12.754 13.138C12.9313 12.9047 13.02 12.606 13.02 12.242C13.02 11.794 12.8707 11.4347 12.572 11.164C12.2827 10.884 11.858 10.6927 11.298 10.59C10.738 10.4873 10.0567 10.464 9.254 10.52V9.47L12.418 7.286V7.174L7.798 7.202V5.746H14.196V7.468L11.242 9.568V9.694C12.1193 9.65667 12.8147 9.75467 13.328 9.988C13.8413 10.2213 14.21 10.5387 14.434 10.94C14.658 11.3413 14.77 11.7893 14.77 12.284C14.77 12.872 14.63 13.3853 14.35 13.824C14.0793 14.2627 13.664 14.6033 13.104 14.846C12.544 15.0793 11.8393 15.196 10.99 15.196Z" fill="#FBFBFB" />
  </svg>)
}
