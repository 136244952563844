import { Avatar, Box, Button, Chip, Paper, Typography } from "@mui/material";
import {
  Edit,
  Face,
  LocationOn,
  Numbers,
  SportsBaseball,
} from "@mui/icons-material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Grid from "@mui/material/Grid";

export const CoachProfileCard = ({ user, athletes }) => (
  <Paper variant="outlined" sx={{ borderRadius: "24px", backgroundColor: "#FBFBFB !important", border: "none" }}>
    <Box p={3} pb={1}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box
            display={"flex"}
            alignItems={"center"}
            mb={3}
            sx={{
              marginBottom: "14px",
              marginTop: "10px",
            }}
          >
            <Avatar
              src={user?.sessionUser?.user_metadata?.avatar_url}
              sx={{ width: "60px", height: "60px" }}
            />

            <Typography
              variant="body1"
              sx={{
                ml: 2,
                color: "#0F0F0F",
                fontWeight: "600 !important",
                fontSize: "24px",
              }}
            >
              <Typography variant="subtitle1" sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "16px", }}>
                Welcome!
              </Typography>
              {user?.full_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            mb={3}
            sx={{
              marginBottom: "14px",
              marginTop: "10px",
              width: 60,
              height: 60,
              borderRadius: 60,
              backgroundColor: "#F1F3F4",
              "&:hover": {
                backgroundColor: "#dadcdd",
                ".MuiButton-root": {
                  backgroundColor: "transparent",
                },
              },
              float: "right",
            }}
          >
            <Button
              startIcon={<Svgedit />}
              size="small"
              href="/settings"
              sx={{
                minWidth: "70px",
                ".css-8je8zh-MuiTouchRipple-root": {
                  display: "none"
                }
              }}
            ></Button>
          </Box>
        </Grid>
      </Grid>

      {/* <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Button
          color="primary"
          startIcon={<Edit />}
          size="small"
          href="/settings"
        >
          Edit Info
        </Button>
      </Box> */}

      <Box
        display={"flex"}
        alignItems={"center"}
        mb={3}
        flexWrap="wrap"
        sx={{
          "& > *": {
            m: 0.8,
          },
          paddingLeft: 9,
          marginBottom: "18px",
        }}
      >
        <img src={require("../../assets/Group 139.png")} />
        <Typography
          variant="subtitle1"
          sx={{
            color: "#525A65",
            fontSize: "12px",
            fontFamily: "Bricolage Grotesque",
            fontOpticalSizing: "auto",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          {user?.city}, {user?.country}
        </Typography>
        <img
          src={require("../../assets/Group 141.png")}
          style={{ marginLeft: "25px" }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            color: "#525A65",
            fontSize: "12px",
            fontFamily: "Bricolage Grotesque",
            fontOpticalSizing: "auto",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          {athletes && athletes.length}
        </Typography>
        {
          user?.sports_activities ?
            <Typography
              variant="subtitle1"
              sx={{
                color: "#F1F3F4",
                fontSize: "10px",
                fontFamily: "Bricolage Grotesque",
                fontOpticalSizing: "auto",
                fontStyle: "normal",
                fontWeight: 600,
                backgroundColor: "#525A65",
                padding: "6px 13px 6px 13px",
                borderRadius: "30px",
                lineHeight: "normal",
                marginLeft: "19px",
              }}
            >
              {user?.sports_activities}
            </Typography> : null
        }

        {/* <Chip icon={<SportsBaseball />} label={user?.sports_activities} />
        <Chip icon={<Face />} label={user?.athlete_types} />
        <Chip icon={<Numbers />} label={athletes && athletes.length} /> */}
      </Box>
    </Box>
  </Paper>
);

export const Svgedit = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_757_256)">
        <path
          d="M17.4989 17.4991H2.49895V2.4991H8.78105C8.89684 2.4991 9.01053 2.45278 9.09263 2.37068L10.7137 0.749626C10.9916 0.471731 10.7958 -0.00195312 10.4021 -0.00195312H2.50105C1.12 0.000152138 0 1.12015 0 2.4991V17.4991C0 18.8802 1.12 20.0002 2.50105 20.0002H17.5011C18.8821 20.0002 20.0021 18.8802 20.0021 17.4991V9.59805C20.0021 9.20647 19.5284 9.01068 19.2505 9.28647L17.6295 10.9075C17.5474 10.9896 17.5011 11.1012 17.5011 11.2191V17.5012L17.4989 17.4991Z"
          fill="#525A65"
        />
        <path
          d="M5 10.3343V14.1932C5 14.6395 5.36 14.9995 5.80842 14.9995H9.66737C9.8821 14.9995 10.0863 14.9153 10.2379 14.7637L19.6905 5.31111C19.8632 5.13848 19.8632 4.86059 19.6905 4.68796L15.3116 0.311114C15.1389 0.138483 14.8611 0.138483 14.6884 0.311114L5.23579 9.76164C5.08421 9.91322 5 10.1195 5 10.3322V10.3343Z"
          fill="#525A65"
        />
      </g>
      <defs>
        <clipPath id="clip0_757_256">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
