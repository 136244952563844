import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const CustomizedListItemButton = styled(ListItemButton)`
  height: 60px;
  width: 276px;
  border-radius: 10px;
  :hover {
    path {
      fill: #02bd36 !important;
    }
    circle {
      fill: #02bd36 !important;
    }
    rect {
      fill: #02bd36 !important;
    }
    .MuiTypography-root {
      color: #02bd36 !important;
    }
    background-color: #013b12 !important;
  }
`;

export const CustomizedListItemIcon = styled(ListItemIcon)`
  color: #013b12;
`;

export const CustomizedListItemText = styled(ListItemText)`
  color: #013b12;
  .MuiTypography-root {
    font-family: "Bricolage Grotesque", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600 !important;
    font-style: normal;
    font-variation-settings: "wdth" 87.5;
  }
`;

export const CustomizedListItem = styled(ListItem)`
  color: #013b12;
`;

export const CustomizedToolbar = styled(Toolbar)`
  padding: 70px 70px 40px 50px;
  display:flex;
  justify-content:center;
`;
