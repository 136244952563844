import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useForm } from "react-hook-form";
import { SPORT_TYPES } from "../../utils/constant";
import { useCallback, useEffect, useState } from "react";
import {
  getYouTubeVideoId,
  isValidURL,
  isValidYouTubeUrl,
} from "../../utils/validate";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { upsertExercise, get_fill_exercise } from "../../services/query";

export const ExerciseModal = ({ open, handleClose, exercise, onSuccess }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: exercise || {},
  });

  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [videoText, setVideoText] = useState("");
  const [imageText, setImageText] = useState("");
  const [description, setDescription] = useState("");
  const [Tag, setTags] = useState("");
  const [title, setTitle] = useState([]);
  const [Workout, setWorkout] = useState([]);
  const [Workoutvalue, setWorkoutvalue] = useState(null);
  const [Require, setRequire] = useState([
    { require: true, requires_equipment: "Yes" },
    { require: false, requires_equipment: "No" },
  ]);
  const [RequireValue, setRequireValue] = useState(null);
  const [sportTypes, setSportTypes] = useState([]);
  const { user } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    if (!exercise) {
      return;
    }
    setTitle(exercise.title || "");
    setDescription(exercise.description || "");
    setTags(exercise.tags || "");
    setRequireValue(
      exercise.requires_equipment === true
        ? { require: true, requires_equipment: "Yes" } || null
        : { require: false, requires_equipment: "No" } || null
    );
    setVideos(exercise.videos || []);
    setImages(exercise.images || []);
  }, [exercise]);

  useEffect(() => {
    const fetchExercises = async () => {
      const { data: _fillexercise } = await get_fill_exercise(supabase);
      setWorkout(_fillexercise || []);
    };

    fetchExercises();
  }, []);

  console.log(Workout);
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const id = exercise && exercise.id ? { id: exercise.id } : {};
    if (!RequireValue) {
      setLoading(false);
      return alert("Please Select Requires Equipment");
    }
    if (exercise) {
      const payload = {
        ...id,
        description: description,
        title: title,
        tags: Tag,
        requires_equipment: RequireValue.require == true ? true : false,
        videos,
        images,
        coach_id: user.id,
      };
      console.log(payload);
      await upsertExercise(supabase, payload);
    } else {
      if (!Workoutvalue) {
        setLoading(false);
        return alert("Please Select Customize an Exercise");
      }
      const payload = {
        ...id,
        description: description,
        title: title + " " + user.full_name,
        tags: Tag,
        workout: Workoutvalue ? Workoutvalue.title : "",
        requires_equipment: RequireValue.require == true ? true : false,
        videos,
        images,
        coach_id: user.id,
      };
      console.log(payload);
      await upsertExercise(supabase, payload);
    }
    setLoading(false);
    onSuccess();
    handleClose();
  });

  const changeTitle = (newValue) => {
    if (newValue !== null) {
      setTitle(newValue.title === "Custom" ? "" : newValue.title);
      setWorkoutvalue(newValue);
      setDescription(newValue.description);
      setTags(newValue.tags);
      if (newValue.requires_equipment !== null) {
        setRequireValue(
          newValue.requires_equipment === true
            ? { require: true, requires_equipment: "Yes" }
            : { require: false, requires_equipment: "No" }
        );
      } else {
        setRequireValue(null);
      }
    } else {
      setTitle("");
      setWorkoutvalue(null);
      setRequireValue(null);
      setDescription("");
      setTags("");
      setRequire([
        { require: true, requires_equipment: "Yes" },
        { require: false, requires_equipment: "No" },
      ]);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="subtitle">
            {exercise ? "Update" : "Create New"} Exercise
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!exercise && (
            <DialogContentText sx={{ mb: 3 }}>
              Exercises are the building blocks of your training plan. Create a
              new exercise to add to one of your training plans.
            </DialogContentText>
          )}
          <Box
            sx={{
              mb: 2,
            }}
          >
            {exercise ? (
              <div style={{ paddingTop: "5px" }}></div>
            ) : (
              <Box sx={{ mb: 3 }}>
                <Autocomplete
                  options={Workout}
                  sx={{
                    width: "100%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Customize an Exercise" />
                  )}
                  onChange={(e, v) => changeTitle(v)}
                  getOptionLabel={(option) => `${option.title}`}
                  getOptionKey={(option) => option.id}
                />
              </Box>
            )}
            <Box sx={{ mb: 2 }}>
              <TextField
                {...register("title")}
                label="Title"
                type="text"
                value={title}
                onChange={(e, v) => setTitle(e.target.value)}
                fullWidth
                multiline
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                {...register("description")}
                label="Description"
                type="text"
                value={description}
                onChange={(e, v) => setDescription(e.target.value)}
                fullWidth
                multiline
                minRows={3}
              />
            </Box>
            <Box mb={3}>
              <Autocomplete
                options={Require}
                sx={{
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Requires_equipment" />
                )}
                value={RequireValue && RequireValue}
                onChange={(e, v) => setRequireValue(v)}
                getOptionLabel={(option) => `${option.requires_equipment}`}
                getOptionKey={(option) => option.require}
              />
            </Box>
            <Box mb={2}>
              <TextField
                {...register("tags")}
                label="Tags"
                type="text"
                value={Tag}
                onChange={(e, v) => setTags(e.target.value)}
                fullWidth
              />
            </Box>
          </Box>
          <Typography sx={{ mb: 1 }}>Add Youtube Videos</Typography>
          <Box
            sx={{
              mb: 2,
              display: {
                xs: "block",
                sm: "flex",
              },
            }}
          >
            <TextField
              label="Youtube Video URL"
              type="url"
              fullWidth
              value={videoText}
              onChange={(e) => setVideoText(e.target.value)}
              sx={{
                mr: {
                  xs: 0,
                  sm: 1,
                },
                mb: {
                  xs: 2,
                  sm: 0,
                },
                width: "100%",
              }}
            />
            <Button
              variant="contained"
              onClick={() => {
                if (!isValidYouTubeUrl(videoText)) {
                  setVideoText("");
                  return alert("Invalid youtube url");
                }

                setVideos([
                  ...videos,
                  {
                    url: videoText,
                    id: getYouTubeVideoId(videoText),
                  },
                ]);
                setVideoText("");
              }}
            >
              Add
            </Button>
          </Box>
          <Grid2 container spacing={2} sx={{ mb: 2 }}>
            {videos.map((v, i) => (
              <Grid2 key={i} xs={4}>
                <Card
                  sx={{
                    aspectRatio: 16 / 9,
                    background: `url(https://i3.ytimg.com/vi/${v.id}/maxresdefault.jpg) no-repeat center center`,
                    width: "100%",
                    minHeight: 1,
                    backgroundSize: "cover",
                  }}
                >
                  <CardActions
                    disableSpacing
                    sx={{
                      height: "100%",
                      alignItems: "end",
                    }}
                  >
                    <IconButton
                      color="error"
                      sx={{
                        background: "rgba(255, 255, 255, .7) !important",
                        marginLeft: "auto",
                        "&:hover": {
                          background: "rgba(255, 255, 255, 1) !important",
                        },
                      }}
                      onClick={() =>
                        setVideos(videos.filter((vid) => v.id !== vid.id))
                      }
                    >
                      <Delete />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid2>
            ))}
          </Grid2>

          {/* IMAGES */}
          {/* IMAGES */}
          {/* IMAGES */}
          {/* IMAGES */}
          <Typography sx={{ mb: 1 }}>Add Images</Typography>
          <Box
            sx={{
              mb: 2,
              display: {
                xs: "block",
                sm: "flex",
              },
            }}
          >
            <TextField
              label="Paste Image URL"
              type="url"
              fullWidth
              value={imageText}
              onChange={(e) => setImageText(e.target.value)}
              sx={{
                mr: {
                  xs: 0,
                  sm: 1,
                },
                mb: {
                  xs: 2,
                  sm: 0,
                },
                width: "100%",
              }}
            />
            <Button
              variant="contained"
              onClick={() => {
                if (!isValidURL(imageText)) {
                  setImageText("");
                  return alert("Invalid url");
                }

                setImages([...images, imageText]);
                setImageText("");
              }}
            >
              Add
            </Button>
          </Box>
          <Grid2 container spacing={2}>
            {images.map((image, i) => (
              <Grid2 key={i} xs={4}>
                <Card
                  sx={{
                    aspectRatio: 16 / 9,
                    background: `url(${image}) no-repeat center center`,
                    width: "100%",
                    minHeight: 1,
                    backgroundSize: "cover",
                  }}
                >
                  <CardActions
                    disableSpacing
                    sx={{
                      height: "100%",
                      alignItems: "end",
                    }}
                  >
                    <IconButton
                      color="error"
                      sx={{
                        background: "rgba(255, 255, 255, .7) !important",
                        marginLeft: "auto",
                        "&:hover": {
                          background: "rgba(255, 255, 255, 1) !important",
                        },
                      }}
                      onClick={() =>
                        setImages(images.filter((img) => image !== img))
                      }
                    >
                      <Delete />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid2>
            ))}
          </Grid2>
          <Grid2 xs={4} sx={{ height: 20 }}></Grid2>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="success" disabled={loading}>
            {exercise ? "Update" : "Create"}
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
