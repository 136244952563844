import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    TextField,
    Typography,
} from "@mui/material";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { AlarmAdd, CalendarMonth } from "@mui/icons-material";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import { useEffect, useState, useMemo } from "react";
import { getReadableTextColor } from "../../utils/styles/theme";
import { deleteSBEvent } from "../../services/query";
import { supabase } from "../../services/supabase";
import { useCalendar } from "../../providers/CalendarProvider";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


const locales = {
    "en-US": enUS,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

export const DashboardCalendarComponent = ({
    defaultView,
    height,
    title,
    toggles,
    views,
    calendars,
    onNewEventClick,
    onEventSelect,
    onReloadRequest,
    onEventDelete = () => null,
    onCalendarToggle,
}) => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { deleteEvent } = useCalendar();

    const deleteCEvent = async (calId, evId, gc_id) => {
        await deleteEvent(calId, gc_id);
        await deleteSBEvent(supabase, evId);

        onEventDelete(calId, evId);
    };
    useEffect(() => {
        if (!calendars || calendars.length === 0) {
            return;
        }
        const today = new Date();
        // const month = today.getMonth() + 1;
        // const year = today.getFullYear();
        let date = today.getDate();

        const allEvents = calendars
            .filter((cal) => cal.enabled)
            .map((cal) =>
                cal.events.map((e) => {
                    const eventDate = new Date(e.date);
                    const evendate = eventDate.getDate();

                    return {
                        allday: true,
                        color: evendate === date ? "#2998E9" : null,
                        backgroundColor: evendate === date ? "#043A8D" : null,
                        resource: e.payload,
                        start: startOfDay(e.date),
                        end: endOfDay(e.date),
                        id: e.id,
                        calendarId: cal.payload.calendarId,
                        title: e.payload.summary + " " + " Exercise : " + e.workout,
                    };
                })
            )
            .flat();
        setEvents(allEvents);
    }, [calendars]);


    return (
        <>
            {!!selectedEvent && (
                <EventModal
                    event={selectedEvent}
                    open={!!selectedEvent}
                    onClose={() => setSelectedEvent(null)}
                    onDelete={(calId, gcalEvId, evId) => {
                        if (window.confirm("Are you sure you want to delete this event?")) {
                            deleteCEvent(calId, evId, gcalEvId);
                            setSelectedEvent(null);
                            onEventDelete(calId, evId, gcalEvId);
                        }
                    }}
                />
            )}
            <Paper variant="outlined" mb={3} sx={{ borderRadius: "24px", height: "670px", backgroundColor: "#FBFBFB !important", border: "none" }}>
                <Box p={2} pb={0} display="flex" alignItems="center" sx={{ padding: "25px" }}>
                    <Typography variant="subtitle" sx={{
                        color: "#525A65",
                        fontFamily: "Bricolage Grotesque,sans-serif",
                        fontOpticalSizing: "auto",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "16px",
                        marginTop: "5px",
                    }}>
                        {title}
                    </Typography>
                    <Button
                        startIcon={<AddIcon />}
                        size="small"
                        sx={{
                            marginLeft: "auto",
                            color: "#E67B02",
                            border: "solid 1px",
                            fontFamily: "Bricolage Grotesque,sans-serif",
                            fontOpticalSizing: "auto",
                            fontStyle: "normal",
                            fontWeight: 600,
                            textTransform: "none",
                            paddingRight: "10px",
                            borderRadius: "20px",
                            ".MuiSvgIcon-root": {
                                fontSize: "14px",
                                paddingLeft: "2px",
                            },
                            "&:hover": {
                                color: "#c96b00"
                            },
                            ".MuiButton-icon": {
                                widht: "10px",
                                paddingLeft: "3px",
                                marginRight: "0px"
                            },
                        }}
                        onClick={onNewEventClick}
                    >
                        Add training
                    </Button>
                </Box>
                {toggles && (
                    <Box px={2} pt={2}>
                        {calendars.map((cal) => (
                            <Chip
                                key={cal.calendarId}
                                size="small"
                                label={cal?.payload.title?.replace("@gmail.com", "")}
                                variant={!cal.enabled ? "outlined" : "filled"}
                                sx={{
                                    fontWeight: 600,
                                    backgroundColor: !cal.enabled
                                        ? "transparent"
                                        : cal.payload.color,
                                    color: !cal.enabled
                                        ? cal.payload.color
                                        : getReadableTextColor(cal.payload.color),
                                    margin: 0.5,
                                    "&:hover": {
                                        backgroundColor: cal.payload.color,
                                        color: cal.enabled
                                            ? getReadableTextColor(cal.payload.color)
                                            : cal.payload.color,
                                    },
                                }}
                                onClick={() => {
                                    onCalendarToggle(cal.id);
                                }}
                            />
                        ))}
                    </Box>
                )}
                <Box sx={{
                    px: 1, py: 2, fontSize: "12px",
                    paddingLeft: "0px",
                    paddingRight: "0px",

                }}>
                    <MyCalendar events={events} setSelectedEvent={setSelectedEvent}></MyCalendar>

                    <Divider sx={{ padding: "0px" }} />
                    <Box p={2} pb={0} display="flex" alignItems="center" sx={{ justifyContent: "center", paddingBottom: "10px" }}>
                        <Typography variant="redirect" onClick={() => { window.location.href = "/calendar" }} sx={{
                            cursor: "pointer",
                            color: "#525A65",
                            fontWeight: "600 !important",
                            fontSize: "12px",
                            marginTop: "16px",
                        }}>
                            View your calendar
                            <hr style={{ position: "relative", top: "-10px" }} />
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

const EventModal = ({ event, onClose, open, onDelete }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{event.title}</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2">Training Program: </Typography>

                <div dangerouslySetInnerHTML={{ __html: event.resource.description }} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() =>
                        onDelete(event.calendarId, event.resource.id, event.id)
                    }
                    color="error"
                >
                    Delete Event
                </Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

// Custom Table Component

export const EventTable = ({ events, currentDate, setSelectedEvent }) => {
    const [sortOrder, setSortOrder] = useState('asc'); // State to track sorting order

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday of the current week

    // Memoize filtered events to avoid recalculating on every render
    const filteredEvents = useMemo(() => {
        return events.filter(event => {
            const eventStart = new Date(event.start);
            const eventEnd = new Date(event.end);
            return (eventStart <= endOfWeek && eventEnd >= startOfWeek);
        });
    }, [events, startOfWeek, endOfWeek]);

    // Sort events based on the selected order
    const sortedEvents = useMemo(() => {
        return filteredEvents.sort((a, b) => {
            const dateA = new Date(a.start);
            const dateB = new Date(b.start);
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
    }, [filteredEvents, sortOrder]);

    // Toggle sort order
    const handleSortToggle = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    return (
        <TableContainer sx={{ height: 432 }}>
            {sortedEvents.length > 0 ? (
                <Table stickyHeader aria-label="sticky table" sx={{
                    borderSpacing: "10px 11px",
                    borderCollapse: "unset",
                    border: "none !important",
                }}>
                    <TableBody>
                        {sortedEvents.map((event, index) => {
                            console.log(event, index)
                            const eventDate = new Date(event.start).toDateString();
                            const isFirstOccurrence = index === 0 || new Date(sortedEvents[index - 1].start).toDateString() !== eventDate;
                            return (
                                <TableRow role="checkbox" tabIndex={-1} key={event.id || event.title}>
                                    <TableCell sx={{
                                        color: event.color, fontSize: "12px", width: "100px", padding: "0px 30px 14px 35px", border: "none !important", lineHeight: "0px !important"
                                    }}>
                                        {isFirstOccurrence && (
                                            <>
                                                <div style={{ width: "35px", textAlign: "center", marginBottom: "5px" }}>
                                                    {new Date(event.end).toString().split(" ")[0].toUpperCase()}
                                                </div>
                                                <div style={{ backgroundColor: event.backgroundColor, width: "35px", height: "35px", textAlign: "center", paddingTop: "17px", fontSize: "18px", borderRadius: "8px" }}>
                                                    {new Date(event.end).getDate()}
                                                </div>
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell onClick={() => setSelectedEvent(event)} sx={{
                                        cursor: "pointer",
                                        fontSize: "12px", borderRadius: "8px", backgroundColor: "#F1F3F4 !important",
                                        padding: "22px !important",
                                        borderBottom: "none !important", lineHeight: "0px !important"
                                    }}>
                                        <div style={{ color: "#0F0F0F", marginBottom: "15px" }}>
                                            <span style={{ fontWeight: "600 !important" }}>{event.title.split("Exercise : ")[0]}</span>
                                        </div>
                                        <div style={{ color: "#525A65", marginBottom: "5px" }}>
                                            <span style={{ fontWeight: "600 !important" }}>{event.title.split("Exercise : ")[1]?.trim()}</span>
                                        </div>
                                    </TableCell>
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            ) : (
                <Box p={2} pb={0} display="flex" alignItems="center" sx={{ padding: "25px", justifyContent: "center" }}>
                    <Typography variant="h3 " sx={{
                        color: "#525A65",
                        fontFamily: "Bricolage Grotesque,sans-serif",
                        fontOpticalSizing: "auto",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "16px",
                        marginTop: "5px",
                        marginBottom: "10px"
                    }}>Not Found Calendar</Typography>
                </Box>
            )}
        </TableContainer>
    );
};

export const MyCalendar = ({ events, setSelectedEvent }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        const today = new Date();
        const currentWeekStart = new Date(currentDate);
        currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay()); // Get the start of the current week
        setDisable(currentWeekStart.toDateString() === today.toDateString());
    }, [currentDate]);

    const updateDateDisplay = () => {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday of the current week
        const monthName = startOfWeek.toLocaleString('default', { month: 'long' }).toUpperCase();
        const startDay = startOfWeek.getDate();
        const endDay = endOfWeek.getDate();
        return `${monthName} ${startDay}-${endDay}`;
    };

    const handlePrevClick = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - 7); // Subtract 7 days
        setCurrentDate(newDate);
    };

    const handleNextClick = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 7); // Add 7 days
        setCurrentDate(newDate);
    };

    return (
        <>
            <div className="custom-toolbar" style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                padding: "15px 27px 10px 25px"
            }}>
                <Button onClick={handlePrevClick} type="button" style={buttonStyle} className="buttonCal" disabled={disable}>Previous Week</Button>
                <span style={{ marginBottom: "20px", color: "#525A65" }}>{updateDateDisplay().toUpperCase()}</span>
                <Button onClick={handleNextClick} type="button" style={buttonStyle} className="buttonCal">Next Week</Button>
            </div>
            <Divider sx={{ paddingTop: "4px" }} />
            <EventTable events={events} currentDate={currentDate} setSelectedEvent={setSelectedEvent} />
        </>
    );
};

const buttonStyle = {
    color: "#525A65",
    border: "solid 1px",
    borderRadius: "30px",
    fontSize: "11px",
    backgroundColor: "unset",
    padding: "6px 9px 6px 9px",
    marginBottom: "20px ",
    cursor: "pointer",
    ".buttonCal:hover": {
        backgroundColor: "#525A65",
        color: "white"
    },
    lineHeightt: "14px",
    height: "25px",
    textTransform: "none"
};