import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  CalendarMonth,
  Info,
  PersonAdd,
  Pool,
  WarningRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import AthleteInviteModal from "../AthleteInvitationModal/AthleteInvitationModal";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { getAthletesByCoachId } from "../../services/query";
import { getDistanceText } from "../../utils/calendar";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";

export const searchInObjects = (searchTerm, objectsArray) => {
  // Convert the search term to lowercase for case-insensitive search
  const lowerSearchTerm = searchTerm.toLowerCase();

  // Filter the array of objects based on whether the search term is found in any string value
  return objectsArray.filter((obj) => {
    return Object.keys(obj.athletes).some((key) => {
      const value = obj.athletes[key];

      // Check if the value is a string, and if the search term is a substring of it
      return (
        typeof value === "string" &&
        value.toLowerCase().includes(lowerSearchTerm)
      );
    });
  });
};

const cols = [
  {
    id: "athletes",
    label: "Athlete",
    format: (athlete) => {
      const name = athlete.first_name
        ? `${athlete.first_name} ${athlete.last_name}`
        : athlete.full_name
          ? athlete.full_name
          : athlete.email;
      return (
        <Box display="flex" alignItems="center">
          <Avatar
            alt={require("../../assets/Group 15.png")}
            src={require("../../assets/Group 15.png")}
            style={{ width: 32, height: 32, marginRight: 8 }}
          />
          <span
            style={{
              color: "#525A65",
              fontFamily: "Bricolage Grotesque",
              fontOpticalSizing: "auto",
              fontStyle: "normal",
              fontSize: "12px",
            }}
          >
            {name.replace("@gmail.com", "")}
          </span>
        </Box>
      );
    },
  },
  {
    id: "athletes",
    label: "Status",
    format: (athlete) => <GetAthleteStatus athlete={athlete} />,
  },
];

export const GetAthleteStatus = ({ athlete }) =>
  athlete.status === "PENDING" ? (
    <Chip
      size="small"
      color="info"
      label="Invited"
      icon={<Info />}
      sx={{ fontWeight: 500, color: "#F1F3F4", backgroundColor: "#999999" }}
    />
  ) : athlete.calendars.length === 0 ? (
    <Chip
      size="small"
      color="error"
      label="No Calendar"
      icon={<WarningRounded />}
      sx={{ pl: 0.3, fontWeight: 500, color: "#F1F3F4", backgroundColor: "#999999" }}
    />
  ) : athlete.events.filter((e) => e.date >= format(new Date(), "yyyy-MM-dd"))
    .length === 0 ? (
    <Chip
      size="small"
      color="warning"
      label="No Schedule"
      icon={<CalendarMonth />}
      sx={{ pl: 0.3, fontWeight: 500, color: "#F1F3F4", backgroundColor: "#999999" }}
    />
  ) : (
    <Chip
      size="small"
      color="success"
      label={`${getDistanceText(
        athlete.events[athlete.events.length - 1].date
      )}`}
      icon={<CalendarMonth />}
      sx={{ pl: 0.3, fontWeight: 500, color: "#F1F3F4", backgroundColor: "#999999" }}
    />
  );

export const PaymentDialog = ({ open, handleClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const supabase = useSupabase();
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box p={2}>
        <Typography variant="h6">Subscription</Typography>
        <Typography variant="subtitle">
          You need to subscribe to add more athletes
        </Typography>
        <br />
        <br />
        <input
          type="hidden"
          name="priceId"
          value={process.env.REACT_APP_STRIPE_PRICE}
        />
        <br />
        <button
          type="submit"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              const { data } = await supabase.functions.invoke("st-checkout", {
                body: {
                  priceId: process.env.REACT_APP_STRIPE_PRICE,
                  baseUrl: window.location.origin,
                },
              });
              window.location = data.url;
            } catch (error) {
              alert(error.message);
            }
            setLoading(false);
          }}
        >
          Subscribe
        </button>
      </Box>
    </Dialog>
  );
};

export const AthletesTable = ({ pic, name, info, onAthletesLoad }) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const supabase = useSupabase();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getAthletes = () => {
    getAthletesByCoachId(supabase, user.id).then(({ data }) => {
      setAthletes(data || []);
      onAthletesLoad && onAthletesLoad(data || []);
    });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getAthletes();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden", borderRadius: "24px", height: "671px", backgroundColor: "#FBFBFB !important", border: "none" }}>
      <AthleteInviteModal
        onSuccess={getAthletes}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <PaymentDialog
        open={paymentOpen}
        handleClose={() => {
          setPaymentOpen(false);
        }}
      />
      <Box
        p={2}
        pb={1}
        display="flex"
        alignItems="center"
        sx={{ padding: "23px" }}
      >
        {/* <Pool style={{ marginRight: 12 }} /> */}
        <Typography
          variant="subtitle"
          sx={{
            color: "#525A65",
            fontFamily: "Bricolage Grotesque,sans-serif",
            fontOpticalSizing: "auto",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            marginTop: "5px",
          }}
        >
          Your Clients:
        </Typography>
        {loading && <CircularProgress size={20} sx={{ marginLeft: "auto" }} />}
        <Button
          startIcon={<AddIcon />}
          size="small"
          sx={{
            marginLeft: "auto",
            color: "#A080E8",
            border: "solid 1px",
            fontFamily: "Bricolage Grotesque,sans-serif",
            fontOpticalSizing: "auto",
            fontStyle: "normal",
            fontWeight: 600,
            textTransform: "none",
            paddingRight: "10px",
            borderRadius: "20px",
            ".MuiSvgIcon-root": {
              fontSize: "14px",
              paddingLeft: "2px",
            },
            ".MuiButton-icon": {
              widht: "10px",
              paddingLeft: "3px",
              marginRight: "0px"
            },
          }}
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              const { data } = await supabase.functions.invoke("st-sub-status");
              if (athletes.length >= 3 && !data.isSubscribed) {
                setPaymentOpen(true);
              } else {
                setOpen(true);
              }
            } catch (error) {
              alert(error.message);
            }
            setLoading(false);
          }}
        >
          Invite
        </Button>
      </Box>
      <Box px={2} pb={2} sx={{ padding: "13px 23px 31px 23px" }}>
        <TextField
          id="outlined-search"
          label="Search for Client..."
          type="search"
          fullWidth
          value={search}
          sx={{
            ".MuiFormLabel-root": {
              fontFamily: "Bricolage Grotesque,sans-serif",
              fontOpticalSizing: "auto",
              fontStyle: "normal",
              fontWeight: 600,
              paddingLeft: "7px",
              fontSize: "12px",
            },
            ".MuiInputBase-root": {
              borderRadius: "30px",
              height: "40px",
            },
            ".css-1ut4ayd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
              padding: "0",
              fontSize: "15px",
            },
            ".MuiOutlinedInput-notchedOutline": {
              bottom: "-3px",
              top: "-2px",
              border: "solid 1px #525A65",
            },
            ".MuiInputBase-input": {
              fontFamily: "Bricolage Grotesque,sans-serif",
              fontOpticalSizing: "auto",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              color: "#525A65 !important",
              padding: "10px 24px",
              marginTop: "4px"
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Divider />
      <TableContainer sx={{ maxHeight: 670 }}>
        <Table stickyHeader aria-label="sticky table" sx={{
          padding: "0px 20px 0px 20px", borderSpacing: "0px 11px",
          "tr td:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
          },
          "tr td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
          }
        }}>
          {/* <TableHead>
            <TableRow>
              {cols.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    p: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead> */}
          <TableBody>
            {searchInObjects(search, athletes).map((row, i) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={() => navigate(`/athlete/${row.athletes.id}`)}
                  sx={{ cursor: "pointer" }}
                >
                  {cols.map((column, i) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={i}
                        align={column.align}
                        sx={{
                          p: {
                            xs: 1,
                            sm: 2,
                          },
                          backgroundColor: "#F1F3F4",
                          padding: "15px",
                          borderBottom: "0px",
                          ".MuiChip-root": {
                            float: "right"
                          }
                        }}
                        id={i}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
