import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import { sideBarConfig } from "./sideBar.config";
import { useAuth } from "../../providers/AuthContextProvider";
import { ProtectedAdmin } from "../../routes/Admin/ProtectedAdmin";
import {
  CustomizedListItemButton,
  CustomizedListItemIcon,
  CustomizedListItemText,
  CustomizedListItem,
  CustomizedToolbar,
} from "./style";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from "react";


export const drawerWidth = 300;

export const SideBar = ({
  drawerOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  handleDrawerToggle
}) => {
  const { session, user } = useAuth();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    handleDrawerClose(); // Close the drawer after clicking
  };
  if (!session) {
    return null;
  }


  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      <List>
        {sideBarConfig.map((item, index) =>
          item.name === "Admin" ? (
            ProtectedAdmin(user) ? (
              <CustomizedListItem key={item.name}>
                <CustomizedListItemButton
                  component={Link}
                  disabled={window.location.pathname === "/coach-onboarding" ? true : false}
                  to={item.path}
                  onClick={() => handleItemClick(item.name)}
                  sx={{
                    backgroundColor: window.location.pathname === item.path ? '#013B12' : 'transparent', // Change color here
                    "path": {
                      fill: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                    "circle": {
                      fill: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                    "rect": {
                      fill: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                    ".css-1l6tx9d .MuiTypography-root": {
                      color: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                  }}
                >
                  <CustomizedListItemIcon>{item.icon}</CustomizedListItemIcon>
                  <CustomizedListItemText primary={item.name} />
                </CustomizedListItemButton>
              </CustomizedListItem>
            ) : (
              <div key={item.name}></div>
            )
          ) : (
            <CustomizedListItem key={item.name}>
              <CustomizedListItemButton
                component={Link}
                to={item.path}
                onClick={() => handleItemClick(item.name)}
                sx={{
                  backgroundColor: window.location.pathname === item.path ? '#013B12' : selectedItem === item.name ? '#013B12' : 'transparent', // Change color here
                  "path": {
                    fill: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                  "circle": {
                    fill: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                  "rect": {
                    fill: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                  ".css-1l6tx9d .MuiTypography-root": {
                    color: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                }}
              >
                <CustomizedListItemIcon>{item.icon}</CustomizedListItemIcon>
                <CustomizedListItemText primary={item.name} />
              </CustomizedListItemButton>
            </CustomizedListItem>
          )
        )}
      </List>
    </Box >
  );

  return (
    <>
      <AppBar
        sx={{
          width: { sm: `calc(100% - 0px)` },
          display: "none",
          // ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        <CustomizedToolbar>
          <img
            src={require("../../Logo.png")}
            alt={require("../../Logo.png")}
            loading="lazy"
            width={218}
          />
        </CustomizedToolbar>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "0px",
          },
          display: { xs: "none", sm: "none", md: "block" },
        }}
        open
      >
        <CustomizedToolbar>
          <img className="logo-coach"
            src={require("../../Logo.png")}
            alt={require("../../Logo.png")}
            loading="lazy"
            width={218}
          />
        </CustomizedToolbar>
        {drawer}
      </Drawer>
    </ >
  );
};


{/* <Drawer
  variant="permanent"
  sx={{
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: "border-box",
      borderRight: "0px",
    },
    display: { xs: "none", sm: "none", md: "block" },
  }}
>
  <CustomizedToolbar>
    <img
      src={require("../../Logo.png")}
      alt={require("../../Logo.png")}
      loading="lazy"
      width={218}
    />
  </CustomizedToolbar>
  {drawer}
</Drawer> */}